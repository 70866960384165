import React from 'react';
import { Card, Menu, Portal, useMantineTheme } from '@mantine/core';
import ContextMenuButton from './ContextButton'; // Adjust the path as per your project structure
import "./Preset.css"
import { t } from 'i18next';

interface ContextMenuProps {
  visible: boolean;
  x: number;
  y: number;
  docId: string | null;
  folderId: string | null;
  folderTitle: string | null;
  currentName: string;
  folderEmpty: string | null;
  onRename: (docId: string, currentName: string) => void;
  onRenameFolder: (folderId: string, currentName: string) => void;
  onRenameEmptyFolder: (folderId: string, newName: string) => void;
  onRenameInitialFolder: (folderEmpty: string, currentName: string) => void;
  onDelete: () => Promise<void>;
  onDeleteFolder: () => void;
  onDeleteEmptyFolder: () => void;
  onDeleteInitialFolder: () => void;
}

const ContextMenu: React.FC<ContextMenuProps> = ({
  visible,
  x,
  y,
  docId,
  folderId,
  folderTitle,
  folderEmpty,
  currentName,
  onRenameInitialFolder,
  onDeleteInitialFolder,
  onRename,
  onRenameFolder,
  onRenameEmptyFolder,
  onDelete,
  onDeleteFolder,
  onDeleteEmptyFolder,
}) => {
  const theme = useMantineTheme();

  if (!visible) return null;
  return (
    <Portal>
      <div className="context-menu-container" style={{ top: y, left: x }}>
        <Menu opened={visible}>
          {docId ? (
            <Card
              style={{
                backgroundColor: theme.colors.grey[3],
                height: '5rem',
                width: '8.25rem',
                padding: theme.spacing.xs,
                borderRadius: theme.radius.sm,
              }}
            >
              <ContextMenuButton onClick={() => onRename(docId, currentName)}>
                {t("RENAME")}
              </ContextMenuButton>
              <ContextMenuButton onClick={onDelete}>{t("DELETE")}</ContextMenuButton>
            </Card>
          ) : folderId ? (
            <Card
              style={{
                backgroundColor: theme.colors.grey[3],
                height: '5rem',
                width: '8.25rem',
                padding: theme.spacing.xs,
                borderRadius: theme.radius.sm,
              }}
            >
              <ContextMenuButton onClick={() => onRenameFolder(folderId, currentName)}>
                {t("RENAME")}
              </ContextMenuButton>
              <ContextMenuButton onClick={onDeleteFolder}>{t("DELETE")}</ContextMenuButton>
            </Card>
          ) : folderTitle ? (
            <Card
              style={{
                backgroundColor: theme.colors.grey[3],
                height: '5rem',
                width: '8.25rem',
                padding: theme.spacing.xs,
                borderRadius: theme.radius.sm,
              }}
            >
              <ContextMenuButton onClick={() => onRenameEmptyFolder(folderTitle, currentName)}>
                {t("RENAME")}
              </ContextMenuButton>
              <ContextMenuButton onClick={onDeleteEmptyFolder}>{t("DELETE")}</ContextMenuButton>
            </Card>
          ) : folderEmpty ? (
            <Card
              style={{
                backgroundColor: theme.colors.grey[3],
                height: '5rem',
                width: '8.25rem',
                padding: theme.spacing.xs,
                borderRadius: theme.radius.sm,
              }}
            >
              <ContextMenuButton onClick={() => onRenameInitialFolder(folderEmpty, currentName)}>
                {t("RENAME")}
              </ContextMenuButton>
              <ContextMenuButton onClick={onDeleteInitialFolder}>{t("DELETE")}</ContextMenuButton>
            </Card>
          ) : null}
        </Menu>
      </div>
    </Portal>
  );
};

export default ContextMenu;
