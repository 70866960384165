import {
  Button,
  Checkbox,
  Flex,
  TextInput,
  Text,
  useMantineTheme,
  Grid
} from "@mantine/core";
import Select from "../../Common/Select/Select";
import { CalendarComponent } from "../CalendarComponent";
import SavePresetModal from "../SavePresetModal";
import { t } from "i18next";
import React from "react";

export const QueryForm = (props: any) => {
  const theme = useMantineTheme();
  const fieldWidth = 184;

  const {
    fundGroup, setFundGroup,
    fundId, setFundId,
    assetIdType, setAssetIdType,
    assetId, setAssetId,
    selectedTypeOfData, setSelectedTypeOfData,
    selectedFromDate, setSelectedFromDate,
    selectedToDate, setSelectedToDate,
    toggleSavePresetModal,
    handleSubmit,
    presetName, setPresetName,
    isSavePresetModalOpen, handleSave,
    selectedFolderName, setSelectedFolderName
  } = props;

  const [checkboxState, setCheckboxState] = React.useState<{
    settled: boolean;
    onLoan: boolean;
    total: boolean;
    approved: boolean;
    pending: boolean;
    executed: boolean;
    confirmed: boolean;
    unsettled: boolean;
  }>({
    settled: false,
    onLoan: false,
    total: false,
    approved: false,
    pending: false,
    executed: false,
    confirmed: false,
    unsettled: false,
  });

  const checkboxConfig: Record<string, { key: keyof typeof checkboxState; label: string }[]> = {
    Holdings: [
      { key: 'settled', label: 'SETTLED' },
      { key: 'onLoan', label: 'ON_LOAN' },
      { key: 'total', label: 'TOTAL' },
    ],
    Orders: [
      { key: 'approved', label: 'APPROVED' },
      { key: 'pending', label: 'PENDING' },
    ],
    Trades: [
      { key: 'executed', label: 'EXECUTED' },
      { key: 'confirmed', label: 'CONFIRMED' },
      { key: 'unsettled', label: 'UNSETTLED' },
    ],
    INDEX_CHANGE: [],
    TARGETS_TOLS: [],
  };

  const handleCheckboxChange = (key: keyof typeof checkboxState) => {
    setCheckboxState((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const checkboxes = selectedTypeOfData ? checkboxConfig[selectedTypeOfData] || [] : [];

  const [gridLeft, gridRight, gridTotal] = [3, 7, 10];

  const handleKeyPress = (event: { key: string; }) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  return (
    <div className="fund-query-2">
      <Grid columns={gridTotal} mb="sm">
        <Grid.Col span={gridLeft}>
          <span className='labelfont'>{t("FUND_GROUP")}</span>
        </Grid.Col>
        <Grid.Col span={gridRight}>
          <TextInput
            value={fundGroup}
            onChange={(event) => setFundGroup(event.currentTarget.value)}
            w={fieldWidth}
            size={"xs"}
            radius={"sm"}
            variant="filled"
            mr={theme.spacing.md}
            disabled={!!fundId}
          />
        </Grid.Col>

        <Grid.Col span={gridLeft}>
          <span className='labelfont'>{t("FUND_ID")}</span>
        </Grid.Col>
        <Grid.Col span={gridRight}>
          <TextInput
            value={fundId}
            onChange={(event) => setFundId(event.currentTarget.value)}
            w={fieldWidth}
            size={"xs"}
            radius={"sm"}
            variant="filled"
            mr={theme.spacing.md}
            disabled={!!fundGroup}
          />
        </Grid.Col>

        <Grid.Col span={gridLeft} mt="xl">
          <span className='labelfont'>{t("ASSET_ID_TYPE")}</span>
        </Grid.Col>
        <Grid.Col span={gridRight} mt="xl">
          <Select
            data={[
              { value: 'sedol', label: t("SEDOL") },
              { value: 'cusip', label: t("CUSIP") },
              { value: 'isin', label: t("ISIN") },
              { value: 'bloomberg_ticker', label: t("BLOOMBERG_TICKER") },
              { value: 'acumain_asset_id', label: t("ACUMAIN_ASSET_ID") }
            ]}
            value={assetIdType}
            onChange={(value) => setAssetIdType(value || 'bloomberg_ticker')}
            className="heightcheck"
            w={fieldWidth}
          />
        </Grid.Col>


        <Grid.Col span={gridLeft}>
          <span className='labelfont'>{t("ASSET_ID")}</span>
        </Grid.Col>
        <Grid.Col span={gridRight}>
          <TextInput
            value={assetId}
            onChange={(event) => setAssetId(event.currentTarget.value)}
            w={fieldWidth}
            size={"xs"}
            fs={"5px"}
            radius={"sm"}
            variant="filled"
            mr={theme.spacing.md}
          />
        </Grid.Col>

        <Grid.Col span={gridLeft} mt="lg">
          <span className='labelfont'>{t("TYPE_OF_DATA")}</span>
        </Grid.Col>
        <Grid.Col span={gridRight} mt="lg">
          <Select
            data={[t("HOLDINGS"), t("ORDERS"), t("TRADES"), t('INDEX_CHANGE'), t('TARGETS_TOLS')]}
            value={selectedTypeOfData}
            onChange={(value) => setSelectedTypeOfData(value || "Holdings")}
            ta={"center"}
            variant="transparent"
            w={fieldWidth}
          />
          <Flex mt='sm' ml='-1rem'>
            {checkboxes.map(({ key, label }) => (
              <Flex key={key}>
                <Checkbox
                  color="gray"
                  variant="outline"
                  checked={checkboxState[key]}
                  onChange={() => handleCheckboxChange(key)}
                  ml={'1rem'}
                />
                <Text ml="xs">{t(label)}</Text>
              </Flex>
            ))}
          </Flex>

        </Grid.Col>

        <Grid.Col span={gridLeft} mt="lg">
          <Flex justify="space-between">
            <Text className='date'>{t("DATE")}</Text>
            <span className='datelabel'>{t("FROM")}</span>
          </Flex>
        </Grid.Col>
        <Grid.Col span={gridRight} mt="lg">
          <CalendarComponent
            onSelect={(date) => setSelectedFromDate(date)}
            defaultDateValue={selectedFromDate}
            placeholder='Select Date'
            style={{ top: "31px" }}
            decrementalCalender={true}
          />
        </Grid.Col>

        <Grid.Col span={gridLeft}>
          <Flex justify="space-between">
            <Text className='date'>{"    "}</Text>
            <span className='datelabel'>{t("TO")}</span>
          </Flex>
        </Grid.Col>
        <Grid.Col span={gridRight}>
          <CalendarComponent
            onSelect={(date) => setSelectedToDate(date)}
            defaultDateValue={selectedToDate}
            placeholder='Select Date'
            style={{ top: "31px" }}
            decrementalCalender={true}
          />
        </Grid.Col>
      </Grid>
      <div className='buttonsection'>
        <Button
          w={168}
          h={30}
          bg={"white"}
          c={"black"}
          className="button-style"
          onClick={toggleSavePresetModal} // Open the modal on click
        >
          {t("SAVE_AS_PRESET")}
        </Button>

        <SavePresetModal
          isOpen={isSavePresetModalOpen}
          onClose={toggleSavePresetModal}
          presetName={presetName}
          setPresetName={setPresetName}
          handleSave={handleSave}
          handleKeyPress={handleKeyPress}
          selectedFolderName={selectedFolderName}
          setSelectedFolderName={setSelectedFolderName}
        />

        <Button
          bg={theme.colors.violet[8]}
          w={168}
          h={30}
          className='buttonspace'
          onClick={() => {
            handleSubmit();
            // handleRefresh();
          }}
        >
          {t("GET_DATA")}
        </Button>
      </div>
    </div>
  )
}