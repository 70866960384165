import React from "react";
import CardComponent from "../../components/Homepage/CardComponent";
import {
    CardData,
    DateFilterProps,
    MarketCounterSingle
} from "../../types";
import { Box, Flex, useMantineTheme } from "@mantine/core";
import classes from "./HomePage.module.css";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import SkeletonLoader from "../../components/Common/Skeleton";
import FundsTable from "../../components/Homepage/FundsTable";
import PageTitle from "../../components/Common/PageTitle";
import { useAppSelector } from "../../store/hooks";
import IconWrapper from "../../components/Common/IconWrapper";
import { tableCategoriesOrder } from "../../constants";
import { DateFilter } from "../../components/Common/DateFilter/DateFilter";

const HomePage: React.FC = () => {
    const { t } = useTranslation();
    const theme = useMantineTheme();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { pathname } = useLocation();

    const { counters, loading: loadingCounters } = useAppSelector(state => state.market);
    const apiQuery = pathname.replaceAll("-", "_").replace("/", "").trim();
    const market_state = `${apiQuery}_counters`;
    const countersForCards = (counters as any)?.[market_state] || [];
    const headerTitle = pathname.replace(/\/|-/g, "_").replace(/^_+|_+$/g, "").toUpperCase();
    const headerSubtitle = `${t("TOTAL")} ${countersForCards?.total_funds_in_aggregate_count || 0} ${t("FUNDS")}`;
    const selectedCard = searchParams.get("selected") || "Ready";
    
    const moc_future_days_counters = counters.moc_future_days_counters;
    const showDateFilter = apiQuery === "market_on_close_plus_days" && !loadingCounters && moc_future_days_counters.length > 0;

    let tableCategories = Object.keys(countersForCards?.[`${selectedCard.toLowerCase().replace(' ', '_')}_state_counts`] || {})
        .filter(item => item !== "total_funds") || [];

    tableCategories = tableCategoriesOrder.filter(category => tableCategories.includes(category));

    const typeOfCards = [
        {
            apiKey: "ready_state_counts",
            TITLE: t("READY"),
            icon: <IconWrapper name="readyicon" className="iconStyle-card" />
        },
        {
            apiKey: "not_ready_state_counts",
            TITLE: t("NOT_READY"),
            icon: <IconWrapper name="notreadyicon" className="iconStyle-card" />
        },
        {
            apiKey: "completed_state_counts",
            TITLE: t("COMPLETED"),
            icon: <IconWrapper name="completedicon" className="iconStyle-card" />
        },
    ];

    const handleCardClick = (selectedCard: string) => {
        navigate(`?selected=${encodeURIComponent(selectedCard)}`);
    };

    const dateFilter: DateFilterProps | undefined = showDateFilter ? {
        data: moc_future_days_counters.map((row: any, key: number) => {
            return {
                datePrefix: `T+${key+1}`,
                date: row.target_date,
                text: `${row.ready_state_counts.total_funds} Ready`
            }
        }),
        selectedDate: moc_future_days_counters[0]?.target_date,
        onClick: (value: any) => console.log(value)
    } : undefined;

    return (
        <>
            <PageTitle TITLE={headerTitle} subtitle={headerSubtitle} />
            {dateFilter && <DateFilter {...dateFilter} />}
            <div className={classes.mainContent}>
                {loadingCounters ? (
                    <SkeletonLoader />
                ) : (
                    <Flex justify="center" mb="xl">
                        {typeOfCards.map((card, idx) => (
                            <Box
                                mr={idx + 1 === typeOfCards.length ? "0" : theme.spacing.md}
                                key={card.apiKey}
                                onClick={() => handleCardClick(card.TITLE)}
                            >
                                <CardComponent
                                    readyText={card.TITLE}
                                    actionText={card.TITLE === "Completed" ? t("FOR_REVIEW") : t("FOR_ACTION")}
                                    dataForCards={countersForCards[card.apiKey as keyof MarketCounterSingle] as Partial<CardData>}
                                    isSelected={selectedCard.toUpperCase() === card.TITLE.toUpperCase()}
                                    icon={card.icon}
                                />
                            </Box>
                        ))}
                    </Flex>
                )}
                {tableCategories.map(category => (
                    <FundsTable
                        category={category as keyof CardData}
                        marketState={apiQuery.toUpperCase()}
                        selectedCard={selectedCard}
                        key={category}
                    />
                ))}
            </div>
        </>
    );
};

export default HomePage;
