import { FC, useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { AgGridEvent, CellClickedEvent, ColDef, GridOptions } from "ag-grid-community";
import "@ag-grid-community/styles/ag-grid.css"; // Core CSS
import "@ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "./Agstyles.css";
import { Button, useMantineTheme } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { routes } from "../../routes";
import axios from "axios";
import SkeletonLoader from "../Common/Skeleton";
import { AgTableComponentProps, RowInfo } from "../../types";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { services } from "../../services";
import { ConstantUtils } from "../../utils/constantUtils";
import { hasWritePermission } from "../../utils/permissionUtils";
import { applyDecorators, getTableData, getToleranceSymbol } from "../../utils/conversions";
import CustomNoRowsOverlay from '../Common/customNoRowsOverlay';
import { getBadgeStyles } from "../../utils/getStyles";


export const AgTableComponent: FC<AgTableComponentProps> = ({ rowData }) => {
    const { t, i18n } = useTranslation();
    const theme = useMantineTheme();
    const styles = getBadgeStyles(theme);
    const [languageKey, setLanguageKey] = useState(i18n.language);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation(); // Use useLocation hook
    const { pathname, search } = location;
    const currentLocation = location.pathname;
    const queryParams = new URLSearchParams(search);
    const selected = decodeURI(queryParams.get("selected") || "");
    let target_mode = pathname.split("/")[1]; // Extracting the part after the first slash in the pathname
    target_mode = target_mode.toUpperCase().replace(/-/g, "_");
    const tableData = getTableData("fund");
    const [checkState, setCheckState] = useState<string | boolean>();
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);

    const BoldCellRenderer = ({ value }: { value: string }) => {
        return <span className="boldfont">{value}</span>;
    };
    const [loadingButtons, setLoadingButtons] = useState<Record<number, boolean>>({});

    const I_M_STARTING = t("I_M_STARTING");
    const ME = t("ME")
    const IN = t("IN")
    const OUT = t("OUT")

    useEffect(() => {
        setLanguageKey(i18n.language);
    }, [i18n.language]);

    useEffect(() => {
        const storedStates = localStorage.getItem('wipButtonStates');
        const storedFundStates = localStorage.getItem('FundLocked');

        if (storedFundStates) {
            try {
                const parsedStates = JSON.parse(storedFundStates);
                const transformedStates = Object.fromEntries(
                    Object.entries(parsedStates).map(([key, state]) => [
                        key,
                        state === "true" ? "Me" : "I'm starting"
                    ])
                );

                setWipButtonStates(transformedStates);
                console.log("wipButtonStates", transformedStates);
            } catch (error) {
                console.error("Failed to parse JSON:", error);
                setWipButtonStates({});
            }
        }
    }, []);

    const handleWipButtonClick = (rowId: any, params: any) => {
        const currentState = wipButtonStates[rowId];
        const newState = currentState === I_M_STARTING ? ME : I_M_STARTING;
        const updatedStates = { ...wipButtonStates, [rowId]: newState };
        setWipButtonStates(updatedStates);
        localStorage.setItem('wipButtonStates', JSON.stringify(updatedStates));

        if (params.data) {
            const { fund_id, as_of_date, transaction_id, fund_group } = params.data;
            const hasPermission = hasWritePermission(fund_group, "write");
            const event_type = newState === ME ? ConstantUtils.orderClaimStates.claim : ConstantUtils.orderClaimStates.unclaim;

            if (hasPermission) {
                const url = `${services.ORDERCLAIM}?fund_id=${fund_id}`;
                setLoadingButtons(prev => ({ ...prev, [rowId]: true }));

                axios.post(url, {
                    fund_id,
                    date: as_of_date,
                    target_mode,
                    transaction_id,
                    event_type
                })
                    .then(response => {
                        const datacheck = response.data.data;
                        const uuid = response.data.uuid_str;
                        localStorage.setItem('uuid', uuid);

                        if (datacheck === "PENDING") {
                            const handlePending = (retryCount: number) => {
                                axios.get(`${services.ORDERCLAIMSTATUS}?fund_id=${fund_id}&date=${as_of_date}&transaction_id=${transaction_id}&target_mode=${target_mode}&uuid=${uuid}`)
                                    .then(orderClaimResponse => {
                                        const orderClaimData = orderClaimResponse.data.data.response_type;
                                        if (ConstantUtils.successTypes.includes(orderClaimData)) {
                                            setWipButtonStates(prevStates => ({
                                                ...prevStates,
                                                [rowId]: orderClaimData == ConstantUtils.successTypes[0] ? ME : I_M_STARTING,
                                            }));
                                            localStorage.setItem('wipButtonStates', JSON.stringify({ ...wipButtonStates, [rowId]: newState }));
                                        } else if (orderClaimData === "PENDING") {
                                            setWipButtonStates(prevStates => ({
                                                ...prevStates,
                                                [rowId]: t("LOADING"),
                                            }));
                                            if (retryCount < 30) {
                                                setTimeout(() => handlePending(retryCount + 1), 1000);
                                            } else {
                                                setWipButtonStates(prevStates => ({
                                                    ...prevStates,
                                                    [rowId]: I_M_STARTING,
                                                }));
                                                toast.error(t("REFRESH_NEEDED"));
                                            }
                                        } else {
                                            toast.error(t("ORDER_UPDATE_MESSAGE"));
                                            setWipButtonStates(prevStates => ({
                                                ...prevStates,
                                                [rowId]: I_M_STARTING,
                                            }));
                                        }
                                    })
                                    .catch(error => {
                                        console.error(error);
                                        toast.error(t("ORDER_UPDATE_MESSAGE"));
                                    });
                            };
                            handlePending(0);
                        } else {
                            toast.error(t("ORDER_CLAIM_FAILED"));
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        toast.error(t("ORDER_CLAIM_FAILED"));
                    })
                    .finally(() => {
                        setLoadingButtons(prev => ({ ...prev, [rowId]: false }));
                    });
            } else {
                toast.error(t("NOT_AUTHORIZE_MESSAGE"));
            }
        }
    };


    const handleFundIdCellClick = async (params: CellClickedEvent<RowInfo, any>) => {
        if (params.data) {
            const fundId = params.data.fund_id;
            const transaction_id = params.data.transaction_id;
            const fund_state = params.data.fund_state;
            const as_of_date = params.data.as_of_date;
            const target_date = params.data.target_date_data_date;
            const is_fund_locked = params.data.is_fund_locked;
            const fund_summary_type = params.data.fund_summary_type;
            const fund_group = params.data.fund_group;

            console.log("fund_summary_type from fund click", fund_summary_type)

            let initialState = I_M_STARTING;
            if (is_fund_locked === "true") {
                initialState = ME;
            } else if (is_fund_locked === "false") {
                initialState = I_M_STARTING;
            }

            if (target_mode === "MARKET_ON_CLOSE_PLUS_DAYS") {
                target_mode = "MARKET_ON_CLOSE";
            }

            const rowId = params.data.sr_no;
            const currentState = wipButtonStates[rowId] || initialState;
            const newState = initialState === I_M_STARTING ? ME : I_M_STARTING;

            try {
                const dateToUse = target_date || as_of_date;
                const checktargetmode = target_mode === "ALL_FUND_STATUS" ? fund_summary_type : target_mode
                console.log("fund_summary_type", fund_group)
                const navigateUrl = (`${routes.FUNDSDATA}/${fundId}/${transaction_id}/${checktargetmode}/${selected}/${dateToUse}/${fund_group}`);

                let url = '';
                if (currentState === ME) {
                    url = `${navigateUrl}?disabled=false`;
                }
                if (currentState === I_M_STARTING) {
                    url = `${navigateUrl}?disabled=true`;
                }
                navigate(url);
                setWipButtonStates((prevStates) => ({
                    ...prevStates,
                    [rowId]: newState,
                }));
            }
            catch (error) {
                console.error(error);
            }
        }
    };

    const inBadge = styles.inBadge;
    const outBadge = styles.outBadge;
    const commonCellStyle = styles.commonCellStyle;
    const cellWithoutBold = styles.cellWithoutBold;
    const commonCellStyleWithoutTolerance = styles.commonCellStyleWithoutTolerance;

    const cellicon = styles.cellicon;

    const fundIdHeader = t("FUND_ID");
    const fundNameHeader = t("FUND_NAME");
    const CANCEL = t("CANCEL")

    const [wipButtonStates, setWipButtonStates] = useState<Record<number, string>>({});

    const handleWipButtonHover = (rowId: number) => {
        const test = checkState === true ? I_M_STARTING : ME
        setWipButtonStates(prevStates => {
            const currentState = prevStates[rowId] || test;
            if (currentState === ME) {
                return {
                    ...prevStates,
                    [rowId]: CANCEL,
                };
            }
            return prevStates;
        });
    };

    const handleWipButtonLeave = (rowId: number) => {
        const test = checkState === true ? I_M_STARTING : ME
        setWipButtonStates(prevStates => {
            const currentState = prevStates[rowId] || I_M_STARTING;
            if (currentState === CANCEL) {
                return {
                    ...prevStates,
                    [rowId]: ME,
                };
            }
            return prevStates;
        });
    };
    const renderCellWithViolations = (
        params: any,
        valueKey: string,
        toleranceKey: string,
        toleranceLowerKey: string,
        toleranceUpperKey: string,
        decoratorsKey: string
    ) => {
        const value = parseFloat(params.data[valueKey]);
        const tolerance = params.data[toleranceKey];
        const toleranceLower = params.data[toleranceLowerKey];
        const toleranceUpper = params.data[toleranceUpperKey];
        const formattedValue = applyDecorators(value, tableData.find(row => row.key === decoratorsKey)?.decorators);

        if (tolerance === "0.00%") {
            return <div className='firsttextincell'>{formattedValue}</div>;
        }

        if (tolerance) {
            const symbol = getToleranceSymbol(tolerance, toleranceUpper, toleranceLower);
            const backgroundColor = value >= toleranceLower && value <= toleranceUpper
                ? theme.colors.yellow[0]
                : theme.colors.red[0];

            return (
                <div style={{ backgroundColor, textAlign: "right" }}>
                    <div className='spendcashstyles' style={{ marginBottom: "-4rem", paddingRight: "0.5rem", marginRight: theme.spacing.sm }}>{formattedValue}</div>
                    <br />
                    <div style={{ color: theme.colors.grey[5], marginRight: theme.spacing.sm }} className='secondtextincellfunds'>
                        {`${symbol}${tolerance}`}
                    </div>
                </div>
            );
        }

        return <div className='firsttextincell'>{formattedValue}</div>;
    };


    const colDefs = useMemo<ColDef<RowInfo>[]>(

        () => {
            const defaultCols: ColDef<RowInfo>[] = [
                {
                    valueGetter: "node.rowIndex + 1",
                    headerName: "",
                    width: 65,
                    headerClass: `custom-header`,
                    pinned: "left",
                    cellStyle: {
                        textAlign: "right",
                    }
                },

                {
                    field: "fund_id",
                    headerName: fundIdHeader,
                    width: 105,
                    pinned: "left",
                    onCellClicked: (params) => handleFundIdCellClick(params),
                    cellStyle: cellWithoutBold,
                },

                {
                    field: "fund_name",
                    headerName: t("FUND_NAME"),
                    width: 248,
                    pinned: "left",
                    onCellClicked: (params) => handleFundIdCellClick(params),
                    cellStyle: cellWithoutBold,
                },
                {
                    field: "tol_start",
                    headerName: t("START_OF_THE_DAY_TOL_STATUS"),
                    width: 135,
                    cellStyle: cellicon,
                    onCellClicked: (params: CellClickedEvent<RowInfo, any>) => handleFundIdCellClick(params),
                    cellRenderer: (params: any) => {
                        const tolStart = params.data.tol_start;
                        return (
                            <div>
                                {tolStart === 'IN' ? inBadge : outBadge}
                            </div>
                        );
                    },
                },

                {
                    field: "tol_current",
                    headerName: t("CURRENT_TOL_STATUS"),
                    width: 115,
                    cellStyle: cellicon,
                    onCellClicked: (params: CellClickedEvent<RowInfo, any>) => handleFundIdCellClick(params),
                    cellRenderer: (params: any) => {
                        const tolCurrent = params.data.tol_current;
                        if (tolCurrent !== null && tolCurrent !== undefined) {
                            return (
                                <div>
                                    {tolCurrent === 'IN' ? inBadge : outBadge}
                                </div>
                            );
                        } else {
                            return null;
                        }
                    },
                } as ColDef<RowInfo>,

                {
                    field: "data_missing",
                    headerName: `${t("DATA_MISSING")}`,
                    width: 100,
                    cellStyle: commonCellStyle,
                },

                {
                    field: "wic",
                    headerName: `${t("WORK_IN_COMPLETED_BY")}`,
                    width: 120,
                    cellStyle: commonCellStyle,
                },

                {
                    field: "order_status",
                    headerName: t("ORDER_STATUS"),
                    width: 113,
                    onCellClicked: (params) => handleFundIdCellClick(params),
                },

                {
                    field: "wip",
                    headerName: t("WORK_IN_PROGRESS_BY"),
                    width: 126,
                    headerClass: 'common-header-right',
                    cellStyle: {
                        ...commonCellStyle,
                        textAlign: "center",
                    },
                    // Within the cellRenderer for the wip column
                    cellRenderer: (params: any) => {
                        const { wip, is_fund_locked, fund_group } = params.data;
                        const rowId = params.data.sr_no;
                        const hasPermission = hasWritePermission(fund_group, "write");

                        if (!hasPermission) {
                            console.log("hasWritePermission", hasPermission)
                            return (
                                <Button
                                    className={`wippillstyle-disable ${wipButtonStates[rowId] === I_M_STARTING}`}
                                    disabled
                                >
                                    {wipButtonStates[rowId] || I_M_STARTING}
                                </Button>

                            );
                        } else if (is_fund_locked === "true") {
                            // console.log(wipButtonStates[rowId] || ME);
                            setCheckState(wipButtonStates[rowId] != null ? wipButtonStates[rowId] : ME)

                            return (
                                <Button
                                    className={`wippillstyle ${wipButtonStates[rowId] === ME}`}
                                    onClick={() => handleWipButtonClick(rowId, params)}
                                    onMouseEnter={() => handleWipButtonHover(rowId)}
                                    onMouseLeave={() => handleWipButtonLeave(rowId)}
                                    loading={loadingButtons[rowId]} // Use loading prop to display loading state
                                    loaderProps={{ type: 'dots' }}
                                    style={{ backgroundColor: loadingButtons[rowId] ? theme.colors.violet[8] : undefined }}
                                    disabled={isButtonDisabled} // Conditionally disable the button
                                >
                                    {wipButtonStates[rowId] || ME}
                                </Button>
                            );
                        } else {
                            // If fund is not locked, display 'I'm starting' as the wip value
                            return (
                                <Button
                                    className={`wippillstyle ${wipButtonStates[rowId] === I_M_STARTING}`}
                                    onClick={() => handleWipButtonClick(rowId, params)}
                                    onMouseEnter={() => handleWipButtonHover(rowId)}
                                    onMouseLeave={() => handleWipButtonLeave(rowId)}
                                    loading={loadingButtons[rowId]}
                                    loaderProps={{ type: 'dots' }}
                                    style={{ backgroundColor: loadingButtons[rowId] ? theme.colors.violet[8] : undefined }}
                                    disabled={isButtonDisabled}
                                >
                                    {wipButtonStates[rowId] || I_M_STARTING}
                                </Button>
                            );
                        }
                    },
                },
                {
                    field: "spend_cash_bef",
                    headerName: t("SPENDABLE_CASH_BEF"),
                    width: 105,
                    cellStyle: commonCellStyle,
                    headerClass: 'common-header-right',
                    onCellClicked: handleFundIdCellClick,
                    cellRenderer: (params: any) => renderCellWithViolations(
                        params,
                        "spend_cash_bef",
                        "spend_cash_bef_violated_tolerances",
                        "spend_cash_bef_violated_tolerances_lower",
                        "spend_cash_bef_violated_tolerances_upper",
                        "spend_cash_bef"
                    ),
                },
                {
                    field: "spend_cash_aft",
                    headerName: t("SPENDABLE_CASH_AFT"),
                    width: 105,
                    headerClass: 'common-header-right',
                    cellStyle: commonCellStyle,
                    onCellClicked: handleFundIdCellClick,
                    cellRenderer: (params: any) => renderCellWithViolations(
                        params,
                        "spend_cash_aft",
                        "spend_cash_aft_violated_tolerances",
                        "spend_cash_aft_violated_tolerances_lower",
                        "spend_cash_aft_violated_tolerances_upper",
                        "spend_cash_aft"
                    ),
                },
                {
                    field: "unequitized_cash_bef",
                    headerName: t("UNEQUITIZED_CASH_BEF"),
                    headerClass: 'common-header-right',
                    width: 105,
                    cellStyle: commonCellStyle,
                    onCellClicked: handleFundIdCellClick,
                    cellRenderer: (params: any) => renderCellWithViolations(
                        params,
                        "unequitized_cash_bef",
                        "unequitized_cash_bef_violated_tolerances",
                        "unequitized_cash_bef_violated_tolerances_lower",
                        "unequitized_cash_bef_violated_tolerances_upper",
                        "unequitized_cash_bef"
                    ),
                },
                {
                    field: "unequitized_cash_aft",
                    headerName: t("UNEQUITIZED_CASH_AFT"),
                    headerClass: 'common-header-right',
                    width: 105,
                    cellStyle: commonCellStyle,
                    onCellClicked: handleFundIdCellClick,
                    cellRenderer: (params: any) => renderCellWithViolations(
                        params,
                        "unequitized_cash_aft",
                        "unequitized_cash_aft_violated_tolerances",
                        "unequitized_cash_aft_violated_tolerances_lower",
                        "unequitized_cash_aft_violated_tolerances_upper",
                        "unequitized_cash_aft"
                    ),
                },

                {
                    field: "assets",
                    headerName: `${t("ASSET_MAX_ACT_WGT")}`,
                    headerClass: 'common-header-right',
                    width: 110,
                    cellStyle: commonCellStyleWithoutTolerance,
                    onCellClicked: (params) => handleFundIdCellClick(params),
                },
                {
                    field: "industry",
                    headerName: `${t("INDUSTRY_MAX_ACT_WGT")}`,
                    headerClass: 'common-header-right',
                    width: 120,
                    cellStyle: commonCellStyleWithoutTolerance,
                    onCellClicked: (params) => handleFundIdCellClick(params),
                },
                {
                    field: "country",
                    headerName: `${t("COUNTRY_MAX_ACT_WGT")}`,
                    headerClass: 'common-header-right',
                    width: 115,
                    cellStyle: commonCellStyleWithoutTolerance,
                    onCellClicked: (params) => handleFundIdCellClick(params),
                },
                {
                    field: "currency",
                    headerName: `${t("CURRENCY_MAX_ACT_WGT")}`,
                    headerClass: 'common-header-right',
                    width: 115,
                    cellStyle: commonCellStyleWithoutTolerance,
                    onCellClicked: (params) => handleFundIdCellClick(params),

                },
                {
                    field: "size",
                    headerName: `${t("SIZE_MAX_ACT_WGT")}`,
                    headerClass: 'common-header-right',
                    width: 100,
                    cellStyle: commonCellStyleWithoutTolerance,
                    onCellClicked: (params) => handleFundIdCellClick(params),
                },
                {
                    field: "shortpos",
                    headerName: `# ${t("SHORT_POSITIONS")}`,
                    headerClass: 'common-header-right',
                    width: 90,
                    cellStyle: commonCellStyleWithoutTolerance,
                    onCellClicked: (params) => handleFundIdCellClick(params),
                },
                {
                    field: "nohold",
                    headerName: `# ${t("NO_HOLD")}`,
                    headerClass: 'common-header-right',
                    width: 94,
                    cellStyle: commonCellStyleWithoutTolerance,
                    onCellClicked: (params) => handleFundIdCellClick(params),
                },
                {
                    field: "nonbench",
                    headerName: `${t("NON_BENCHMARK")}`,
                    headerClass: 'common-header-right',
                    width: 100,
                    cellStyle: commonCellStyleWithoutTolerance,
                    onCellClicked: (params) => handleFundIdCellClick(params),
                },
                {
                    field: "activerisk",
                    headerName: `${t("ACTIVE_RISK")}`,
                    headerClass: 'common-header-right',
                    width: 100,
                    cellStyle: commonCellStyleWithoutTolerance,
                    onCellClicked: (params) => handleFundIdCellClick(params),
                },
                {
                    field: "equityactiverisk",
                    headerName: `${t("EQUITY_ACTIVE_RISK")}`,
                    headerClass: 'common-header-right',
                    width: 110,
                    cellStyle: commonCellStyleWithoutTolerance,
                },
            ]

            let fieldsToExclude: string[] = [];
            if (selected === 'Completed') {
                fieldsToExclude = ["order_status", "wip", "data_missing"];
            }

            if (selected === 'Not Ready') {
                fieldsToExclude = ["wic", "wip"];
            }

            if (selected === 'Ready') {
                fieldsToExclude = ["data_missing", "wic"];
            }

            return defaultCols.filter(col => !fieldsToExclude.includes(col.field || ""));

        },
        [fundIdHeader, fundNameHeader, wipButtonStates],
    );

    const defaultColDef = useMemo<ColDef>(() => {
        return {
            menuTabs: ["filterMenuTab"],
            filter: "agMultiColumnFilter",
            suppressStickyLabel: true,
            headerClass: "custom-header",
            resizable: true,
            initialWidth: 200,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            headerPosition: "sticky",
            sticky: true,
        };
    }, []);

    const gridOptions: GridOptions = {
        suppressRowHoverHighlight: true, // Disable row hover effect
    };

    const customheight = () => {
        if (rowData.length > 1) {
            const heightInRem = 3.2 + (rowData.length * 2.5);
            return `${heightInRem}rem`;
        } else {
            return "5.68rem";
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setIsLoading(false);
            }
        };
        fetchData();
    }, []);

    const componentLocation = __filename.split('/').pop();
    const hideColumns = componentLocation === 'AllFundStatus.tsx';

    colDefs.filter(colDef => {
        if (hideColumns) {
            return colDef.field !== 'next_step' && colDef.field !== 'wip';
        } else {
            const decorators = tableData.find(row => row.key === colDef.field)?.decorators;
            colDef.valueFormatter = ({ value }) => applyDecorators(value, decorators);
            return true;
        }
    });

    const noRowsOverlayComponentParams = useMemo(() => {
        return {
            noRowsMessageFunc: () => `- ${t("NO_FUNDS_HERE")} -`,
        }
    }, []);

    const rowSelectionType = 'single'

    const calculateMaxHeight = (routes.ALLFUNDSTATUS ? "44rem" : "23.8rem")

    return (
        <div>
            {isLoading ? (
                <SkeletonLoader />
            ) : (
                <div
                    className='ag-theme-quartz'
                    style={{
                        height: customheight(),
                        width: "100%",
                        minHeight: "5.68rem",
                        maxHeight: calculateMaxHeight,
                    }}
                >
                    <AgGridReact
                        key={languageKey}
                        rowData={rowData}
                        columnDefs={colDefs.filter((colDef) => {
                            if (selected === "Completed" || currentLocation.includes(`${routes.ORDERCOMPLETION}`) || selected === "All-Fund-Status") {
                                return colDef.field !== "next_step" && colDef.field !== "wip";
                            } else {
                                return true; // Show all fields for other values of selected
                            }
                        })}
                        defaultColDef={defaultColDef}
                        rowHeight={40}
                        maxConcurrentDatasourceRequests={1}
                        maxBlocksInCache={1}
                        gridOptions={gridOptions}
                        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                        noRowsOverlayComponent={CustomNoRowsOverlay}
                        rowSelection={rowSelectionType}
                        suppressScrollOnNewData={true}
                        onSortChanged={(e: AgGridEvent) => {
                            e.api.refreshCells();
                        }}
                    />
                    <ToastContainer position='top-right' autoClose={8000} />

                </div>
            )}
        </div>
    );
};

export default AgTableComponent;

