import React, { useState, useEffect, useRef } from "react";
import {
  ActionIcon,
  Box,
  Collapse,
  Flex,
  useMantineTheme,
  Text,
  TextInput,
  Grid,
  Accordion,
} from "@mantine/core";
import IconWrapper from "../../Common/IconWrapper";
import { Data, Document, PresetOption2Props } from '../../../types';
import { services } from "../../../services";
import axios from "axios";
import ContextMenu from "../ContextMenu";
import { v4 as uuid_v4 } from "uuid";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../Preset.css";
import useClickOutside from "../Presets/useClickOutside";
import {
  handleEvent,
  handleRenameFolders,
  handleSave as saveRenameFolder,
  updateContextMenu,
} from "../../../utils/handleRename";
import FolderTextInput from "../Presets/FolderTextInput";

import { t } from "i18next";
import { QueryForm } from "./QueryForm";
import { clearFolderFromLocalStorage, createFolder, formatDate, processDates, renameDocument, savePreset } from "./PresetsUtils";
import "./PresetsOption2.css";
import { 
  IconChevronLeft,
  IconChevronRight,
  IconPaperclip,
  IconX,
  IconStar,
  IconChevronUp,
} from "@tabler/icons-react";

export function Preset({
  onSubmit,
  addRecentPresets,
  selectedPreset,
  handleRefresh,
  setErrorMessage,
  isPresetOpen,
  setIsPresetOpen,
  isLeftNavOpen,
  setIsLeftNavOpen
}: PresetOption2Props) {
  const theme = useMantineTheme();
  const DEFAULT_FOLDER_NAME = "Untitled Folder";
  const [creatingFolder, setCreatingFolder] = useState<boolean>(false);
  const [folderTitle, setFolderTitle] = useState<string>("");
  const [folderNames, setFolderNames] = useState(
    Object.keys(localStorage)
      .filter((key) => key.startsWith("folderName"))
      .map((key) => localStorage.getItem(key) || "")
  );
  const [renamingDocId, setRenamingDocId] = useState<string | null>(null);
  const [renamingFolderId, setRenamingFolderId] = useState<string | null>(null);
  const [newName, setNewName] = useState<string>("");
  const [data, setData] = useState<Data[]>([]);
  const [storedFolders, setStoredFolders] = useState<string[]>([]);
  const [newEmptyFolderNameDelete, setEmptyNewFolderNameDelete] = useState<boolean>(false);
  const [newFolderNameDelete, setNewFolderNameDelete] = useState<boolean>(false);
  const [newFolderName, setNewFolderName] = useState("");
  const [newEmptyFolderName, setNewEmptyFolderName] = useState("");
  const [fundGroup, setFundGroup] = useState<string>("");
  const [fundId, setFundId] = useState<string>("");
  const [assetId, setAssetId] = useState<string>("");
  const [assetIdType, setAssetIdType] = useState<string | null>("sedol");
  const [selectedTypeOfData, setSelectedTypeOfData] = useState<string | null>("Holdings");
  const [settled, setSettled] = useState<boolean>(false);
  const [onLoan, setOnLoan] = useState<boolean>(false);
  const [total, setTotal] = useState<boolean>(false);
  const today = new Date();
  const [selectedFromDate, setSelectedFromDate] = useState<Date | null>(today);
  const [selectedToDate, setSelectedToDate] = useState<Date | null>(today);
  const [isSavePresetModalOpen, setIsSavePresetModalOpen] = useState(false);
  const [presetName, setPresetName] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFolderName, setSelectedFolderName] = useState('');
  const [totalGrid, leftGrid, centerGrid, rightGrid] = [12, 1.5, 9, 1.5];
  const inputRef = useRef<HTMLInputElement>(null);
  const [opened, setOpened] = useState<string | null>("Favorites");

  const customChevron = (item: string) =>
    opened === item ? (
      <IconChevronUp size={16} />
    ) : (
      <IconChevronRight size={16} />
    );

  useEffect(() => {
    handleDocumentClick(selectedPreset);
    if(!isLeftNavOpen) setIsLeftNavOpen(true);
  }, [selectedPreset])

  useEffect(() => {
    if(data.length > 0) localStorage.setItem("presetName", JSON.stringify(data));
  }, [data]);

  useEffect(() => {
    fetchData();
    if (inputRef.current) inputRef.current.focus();
  }, []);

  useEffect(() => {
    !creatingFolder && setFolderTitle("");
  }, [creatingFolder])

  const handleDocumentClick = (uuid: string) => {
    setIsLoading(true);

    try {
      if (data) {
        let presetItem = null;
        for (const folder of data) {
          presetItem = folder.documents.find((item: { uuid: string }) => item.uuid === uuid);
          if (presetItem) break;
        }

        if (presetItem) {
          addRecentPresets(uuid)
          setFundId(presetItem.fund_id);
          setAssetId(presetItem.asset_id);
          setFundGroup(presetItem.fund_group);
          setSelectedTypeOfData(presetItem.data_type);
          setSelectedFromDate(new Date(presetItem.start_date));
          setSelectedToDate(new Date(presetItem.end_date));
          setAssetIdType(presetItem.asset_id_type);
          setAssetId(presetItem.asset_id);
          setIsPresetOpen(false);
        } else {
          console.error("No matching item found in local storage for the provided UUID.");
        }
      } else {
        console.error("No data found in local storage.");
      }
    } catch (error) {
      console.error("Error fetching document data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    handleRefresh();
    setIsLoading(true);

    const formData = {
      fund_group: fundGroup,
      fund_id: fundId,
      asset_id: assetId,
      asset_id_type: assetIdType,
      start_date: formatDate(selectedFromDate),
      end_date: formatDate(selectedToDate),
      settled,
      on_loan: onLoan,
      total,
      selectedTypeOfData
    };

    await onSubmit(formData);
    setIsLoading(false);
  };

  const handleSave = async (folderId: string | null = null) => {
    toggleSavePresetModal();
    const uuid = uuid_v4();
    try {
      await savePreset(folderId, uuid, {
        presetName,
        selectedFolderName,
        fundGroup,
        fundId,
        selectedFromDate: formatDate(selectedFromDate),
        selectedToDate: formatDate(selectedToDate),
        settled,
        onLoan,
        total,
        selectedTypeOfData,
        assetId,
        assetIdType,
      });
      fetchData();
   } catch (error: any) {
      console.error("Error making API request:", error);
      setErrorMessage(error.message)
    }
  };

  const toggleSavePresetModal = () => setIsSavePresetModalOpen(!isSavePresetModalOpen);

  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
    docId: null as string | null,
    folderId: null as string | null,
    folderTitle: null as string | null,
    folderEmpty: null as string | null,
  });

  const fetchData = async () => {
    // this will reset the new folder input box at the time new data fetched from backed
    // TODO: Need to handle this when we create new folder but we do not save preset in that new folder
    setNewFolderName("");
    localStorage.removeItem("folderName");
    const storedFolderNames = Object.keys(localStorage)
        .filter((key) => key.startsWith("folderName"))
        .map((key) => localStorage.getItem(key) || "");
      setStoredFolders(storedFolderNames);
    // this will reset the new folder input box at the time new data fetched from backed
    
    try {
      const response = await axios.get(services.GET_PRESETS);
      const { data } = response.data;
      
      if (response.data.api_status === 'success') {
        const names = data.map((item: { _id: any }) => item._id);
        const processedData = processDates(data);
        setData(processedData);
        setFolderNames(names);
      } else {
        console.error('API error:', data.message);
      }
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  const contextMenuRef = useRef<HTMLDivElement>(null);
  useClickOutside({
    ref: contextMenuRef,
    handler: () => setContextMenu({ ...contextMenu, visible: false }),
    condition: contextMenu.visible,
  });

  const togglePreset = () => setIsPresetOpen(!isPresetOpen);

  const handleInputKeyPress = async (
    e: React.KeyboardEvent<HTMLInputElement> | React.DragEvent<HTMLInputElement>,
  ) => {
    if ((e as React.KeyboardEvent).key === "Enter") {
      createFolder(folderTitle);
      setCreatingFolder(false);
    } else if ((e as React.DragEvent).type === "drop") {
      handleDropEvent(e as React.DragEvent<HTMLInputElement>);
    }
  };
  
  const handleInputKeyPressCreateButton = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== "Enter") return;
    if (!folderTitle) toast.error("Folder name cannot be empty");
    else {
      localStorage.setItem("folderName", folderTitle);
      setStoredFolders((prevFolders) => [...prevFolders, folderTitle]);
      setCreatingFolder(false);
    }
  };

  const handleInputBlur = () => {
    const finalFolderTitle = folderTitle.trim() || DEFAULT_FOLDER_NAME;
    localStorage.setItem("folderName", finalFolderTitle);
    setStoredFolders((prevFolders) => [...prevFolders, finalFolderTitle]);
    setCreatingFolder(false);
  };

  const handleDropEvent = async (e: React.DragEvent<HTMLInputElement>) => {
    try {
      e.preventDefault();
      const draggedData = JSON.parse(e.dataTransfer.getData("application/json")) as Document;

      const {
        asset_id, asset_id_type, data_type,
        start_date, end_date,
        name, fund_group, fund_id,
        on_loan, settled, total,
      } = draggedData;

      const targetElement = e.target as HTMLInputElement;
      const folderName = targetElement.placeholder;

      const newDocument: Document = {
        uuid: "",
        asset_id, asset_id_type, data_type,
        start_date, end_date,
        name, fund_group, fund_id,
        on_loan, settled, total,
        folder_name: folderName,
      };

      // Delete the old preset using its UUID
      console.log("Dragged UUID:", draggedData.uuid);
      if (draggedData && typeof draggedData.uuid === "string") {
        const { uuid } = draggedData;
        
        await axios.delete(`${services.GET_PRESETS}/${uuid}`);
        newDocument.uuid = uuid;
				await axios.post(`${services.GET_PRESETS}/${uuid}`, newDocument);

        console.log("Deleted old preset with UUID:", uuid);
        fetchData();
      } else {
        console.error("Invalid UUID:", draggedData?.uuid);
      }
      setCreatingFolder(false);
    } catch (error) {
      console.error("Error creating folder from drop event:", error);
    }
  };

  const handleRename = (docId: string, currentName: string) => {
    setRenamingDocId(docId);
    setNewName(currentName);
    setContextMenu({ ...contextMenu, visible: false });
  };

  const handleRenameFolder = (folderId: string, currentName: string) => {
    setRenamingFolderId(folderId);
    setNewName(currentName);
    setContextMenu({ ...contextMenu, visible: false });
  };

  const handleRenameInputEvent = async (
    e: React.KeyboardEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>,
    docId: string,
  ) => {
    if ((e.type === "keypress" && (e as React.KeyboardEvent).key !== "Enter") || newName.trim() === "") {
      return;
    }
    const check = await renameDocument(docId, newName);
    if(!check) return;
    
    setRenamingDocId(null);
    fetchData();
  };

  const handleRenameFolderInputKeyPress = (
    e: React.KeyboardEvent<HTMLInputElement>,
    folderId: string,
  ) => {
    if (e.key === "Enter" && newName.trim() !== "") {
      try {
        localStorage.setItem(folderId, newName.trim());
        setRenamingFolderId(null);
        console.log("Folder renamed:", folderId, newName);
      } catch (error) {
        console.error("Error renaming folder:", error);
      }
    }
  };

  const handleRightClick = (
    event: React.MouseEvent<HTMLParagraphElement, MouseEvent>,
    docId: string,
  ) => updateContextMenu(event, setContextMenu, { docId });

  const handleFolderRightClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    folderId: string,
  ) => updateContextMenu(event, setContextMenu, { folderId });

  const handleFolderRightClickEmpty = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    folderTitle: string,
  ) => updateContextMenu(event, setContextMenu, { folderTitle: folderTitle || DEFAULT_FOLDER_NAME });

  const handleInitialRightClickEmpty = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    folderEmpty: string,
  ) => updateContextMenu(event, setContextMenu, { folderEmpty });

  const handleDelete = async () => {
    setContextMenu({ ...contextMenu, visible: false });
    if (contextMenu.docId) {
      try {
        const folderNameToDelete = data.find((item) =>
          item.documents.some((doc) => doc.uuid === contextMenu.docId),
        )?._id;
        // localStorage.setItem("deletedFolderName", folderNameToDelete || "");
        setNewFolderName(folderNameToDelete || "");
        await axios.delete(`${services.GET_PRESETS}/${contextMenu.docId}`);
        console.log("Document deleted:", contextMenu.docId);
        fetchData();
      } catch (error) {
        console.error("Error deleting document:", error);
      }
    }
  };

  const handleDeleteFolder = async () => {
    const { folderId } = contextMenu;
    setContextMenu({ ...contextMenu, visible: false });
    if (!folderId) return;
    const folderHasPresets = data.some((item) => item._id === folderId);

    if (folderHasPresets) {
      toast.error("Delete presets in the folder first");
      return;
    }
    try {
      localStorage.removeItem(folderId);
      const storedFolderNames = Object.keys(localStorage)
        .filter((key) => key.startsWith("folderName"))
        .map((key) => localStorage.getItem(key) || "");
      setStoredFolders(storedFolderNames);
      console.log("Folder deleted:", folderId);
    } catch (error) {
      console.error("Error deleting folder:", error);
    }
  };

  const renderFolders = () => {
    const folderMap = new Map<string, Document[]>();
    const presetsWithoutFolder: Document[] = [];

    if (!data || !Array.isArray(data)) {
      fetchData();
      return null;
    }

    data.forEach((item) => {
      if (!item._id) {
        item.documents.forEach((doc: Document) => {
          presetsWithoutFolder.push(doc);
        });
        return;
      }
      if (!folderMap.has(item._id)) {
        folderMap.set(item._id, []);
      }
      for (let i = 0; i < item.documents.length; i++) {
        const doc = item.documents[i];
        folderMap.get(item._id)?.push(doc);
      }
    });

    const sortedFolderEntries = Array.from(folderMap.entries()).sort((a, b) => {
      if (a[0] === "default") return -1;
      if (b[0] === "default") return 1;
      return 0;
    });

    const presetsWithoutFolderContent = presetsWithoutFolder.map((doc, index) => (
      <Flex key={index} align={"center"}>
        {renamingDocId === doc.uuid ? (
          <TextInput
            autoFocus
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            onKeyPress={(e) => handleRenameInputEvent(e, doc.uuid)}
            onBlur={(e) => handleRenameInputEvent(e, doc.uuid)}
            w={200}
            h={28}
            radius={"md"}
            size={"xs"}
            mb="xs"
          />
        ) : (
          <Text
            size={theme.fontSizes.md}
            c={theme.colors.dark[8]}
            style={{ cursor: "pointer" }}
            onClick={() => handleDocumentClick(doc.uuid)}
            onContextMenu={(event) => handleRightClick(event, doc.uuid)}
            mb="xs"
            draggable={presetsWithoutFolder.length > 1}
            onDragStart={(event) => {
              event.dataTransfer.setData(
                "application/json",
                JSON.stringify(doc),
              );
            }}
          >
            {doc.name} {/* Display blank space if name is empty */}
          </Text>
        )}
      </Flex>
    ));

    const foldersContent = sortedFolderEntries.map(([folderName, documents]) => (
    <div key={folderName} className='folder-wrapper'>
      <Accordion.Item key={folderName} value={folderName}>
        <Accordion.Control chevron={customChevron(folderName)}>
          <Flex
            align={"center"}
            onContextMenu={(event) => handleFolderRightClick(event, folderName)}
          >
            {folderName !== "default" && <IconWrapper name='folder' className='allfundqueryicon' />}
            {renamingFolderId === folderName ? (
              <TextInput
                autoFocus
                mt={"0.2rem"}
                mb={"0.2rem"}
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
                onKeyPress={(e) => handleRenameFolderInputKeyPress(e, folderName)}
                onBlur={() => setRenamingFolderId(null)}
                radius={"md"}
                size={"xs"}
                className='inputfolder'
              />
            ) : (
              <TextInput
                ml={"0.2rem"}
                mt={"0.2rem"}
                size={theme.fontSizes.md}
                disabled
                c={theme.colors.dark[8]}
                unstyled
                className='dropfolder'
                onDrop={(event) => {
                  event.preventDefault();
                  const name = event.dataTransfer.getData("text");
                  setFolderTitle(name);
                  handleInputKeyPress(event);
                }}
                onDragOver={(event) => event.preventDefault()}
                placeholder={
                  localStorage.getItem(folderName) || (folderName === "default" ? " " : folderName)
                }
              />
            )}
          </Flex>
            
          </Accordion.Control>

          <Accordion.Panel>
            {documents.map((doc, index) => (
              <Flex key={index} align={"center"}>
                {renamingDocId === doc.uuid ? (
                  <TextInput
                    autoFocus
                    value={newName}
                    onChange={(e) => setNewName(e.target.value)}
                    onKeyPress={(e) => handleRenameInputEvent(e, doc.uuid)}
                    onBlur={(e) => handleRenameInputEvent(e, doc.uuid)}
                    radius={"md"}
                    size={"xs"}
                    mb={theme.spacing.sm}
                    className='inputfolder'
                  />
                ) : (
                  <Text
                    size={theme.fontSizes.md}
                    c={theme.colors.dark[8]}
                    mb={theme.spacing.sm}
                    style={{ cursor: "pointer" }}
                    onClick={() => handleDocumentClick(doc.uuid)}
                    onContextMenu={(event) => handleRightClick(event, doc.uuid)}
                    draggable={documents.length > 1}
                    onDragStart={(event) => {
                      event.dataTransfer.setData(
                        "application/json",
                        JSON.stringify(doc),
                      );
                    }}
                  >
                    {doc.name}
                  </Text>
                )}
              </Flex>
            ))}
          </Accordion.Panel>
        </Accordion.Item>
      </div>
    ));

    return (
      <Accordion radius="sm" chevronPosition="left" variant="filled" mt={"md"} value={opened} onChange={setOpened}>
        {foldersContent.map((content, index) => (
          <React.Fragment key={index}>
            {content}
            <Box mb={3} /> {/* Margin bottom of 3rem */}
          </React.Fragment>
        ))}
        {presetsWithoutFolderContent.length > 0 && (
          <div className="folder-wrapper">
            <Accordion.Item key="Favorites" value="Favorites">
              <Accordion.Control mb="xs" chevron={customChevron('Favorites')}>
                <Flex align={"center"} mt={"0.2rem"}>
                  <IconStar size="1.2rem" color={theme.colors.dark[8]} />
                  <Text ml="xs" size={theme.fontSizes.md} c={theme.colors.dark[8]} pt="0.25rem">
                    Favorites
                  </Text>
                </Flex>
              </Accordion.Control>
              <Accordion.Panel>{presetsWithoutFolderContent}</Accordion.Panel>
            </Accordion.Item>
          </div>
        )}
      </Accordion>
    );
  };

  const setContextMenuVisible = (visible: boolean) => setContextMenu(currentState => ({...currentState, visible}))

  const handleRenameEmptyFolder = (folderId: string, newName: string) => {
    handleRenameFolders("folderName", newName, setEmptyNewFolderNameDelete, setContextMenuVisible);
  };

  const handleRenameEmpty = (folderEmpty: string, currentName: string) => {
    handleRenameFolders(
      "deletedFolderName",
      newFolderName,
      setNewFolderNameDelete,
      setContextMenuVisible,
      fetchData,
    );
  };

  const handleDrop = (event: any) => {
    event.preventDefault();
    const name = event.dataTransfer.getData("text");
    setFolderTitle(name);
    handleInputKeyPress(event);

    Object.keys(localStorage).forEach((key) => {
      if (key.includes("folderName")) {
        localStorage.removeItem(key);
      }
    });

    const storedFolderNames = Object.keys(localStorage)
      .filter((key) => key.startsWith("folderName"))
      .map((key) => localStorage.getItem(key) || "");
    setStoredFolders(storedFolderNames);
  };

  const handleDropLocalStorage = (event: any) => {
    event.preventDefault();
    const name = event.dataTransfer.getData("text");
    setFolderTitle(name);
    handleInputKeyPress(event);
  };

  const checkDelete: string[] = folderNames;
  const handleKeyDownDelete = (event: { key: string }) =>
    handleEvent(event, newFolderName, "deletedFolderName", setNewFolderNameDelete);
  const handleBlurSaveDelete = () =>
    saveRenameFolder(newFolderName, "deletedFolderName", setNewFolderNameDelete);
  const handleKeyDownEmpty = (event: { key: string }) =>
    handleEvent(event, newEmptyFolderName, "folderName", setEmptyNewFolderNameDelete);
  const handleBlurSaveEmpty = () =>
    saveRenameFolder(newEmptyFolderName, "folderName", setEmptyNewFolderNameDelete);
  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };
  const deletedName: string = localStorage.getItem("deletedFolderName") as string;

  return (
    <Flex direction="row-reverse" className="preset-option2-container">
      <div className="preset-leftNav-hidden">
        <ActionIcon
          variant='transparent'
          color={theme.colors.grey[8]}
          onClick={isPresetOpen ? togglePreset : () => {
            setIsLeftNavOpen(!isLeftNavOpen);
          }}
        >
          {isPresetOpen ? <IconX size={"1.2rem"} /> : isLeftNavOpen ? <IconChevronLeft /> : <IconChevronRight />}
        </ActionIcon>
      </div>
      <Collapse in={isLeftNavOpen} className="preset-leftNav" transitionTimingFunction="ease" transitionDuration={100}>
        <Grid columns={totalGrid}>
          <Grid.Col span={leftGrid} style={{textAlign: "right"}}>
            <ActionIcon
              variant='transparent'
              color={theme.colors.grey[8]}
              onClick={togglePreset}
            >
              <IconPaperclip size="1.2rem" />
            </ActionIcon>
          </Grid.Col>
          <Grid.Col span={centerGrid} style={{ alignSelf: "center" }}>
            <Text fw={"700"} size={theme.fontSizes.md} c={theme.colors.dark[8]} style={{ visibility: isPresetOpen ? "visible" : "hidden" }}>
              {t("PRESETS")}
            </Text>
          </Grid.Col>
          <Grid.Col span={rightGrid}>
            <ActionIcon
              variant='transparent'
              color={theme.colors.grey[8]}
              onClick={() => setCreatingFolder(true)}
              className="text-center"
              style={{ visibility: isPresetOpen ? "visible" : "hidden" }}
            >
              <IconWrapper name='createfolder' className='allfundqueryicon' />
            </ActionIcon>
          </Grid.Col>
        </Grid>
        <Collapse key="presets" in={isPresetOpen} transitionTimingFunction={isPresetOpen ? "ease-in" : "ease-out"} className="presets-list">
          <Grid columns={totalGrid}>
            <Grid.Col span={leftGrid}></Grid.Col>
            <Grid.Col span={centerGrid + rightGrid}>
              <div className={"preset-search"}>
                <TextInput
                  h={28}
                  placeholder={`${t("SEARCH")} ${t("PRESETS")}`}
                  leftSection={<IconWrapper name='search' />}
                  radius={"md"}
                  fs={"13px"}
                  size={"xs"}
                />
              </div>
            </Grid.Col>
          </Grid>
          {creatingFolder && (
            <Grid columns={totalGrid}>
              <Grid.Col span={leftGrid}></Grid.Col>
              <Grid.Col span={centerGrid} className='folder-wrapper'>
                {renderFolders()}
                <Flex align="center">
                    <IconWrapper name='folder' className='allfundqueryicon' />
                    <TextInput
                      autoFocus
                      value={folderTitle}
                      onChange={(e) => setFolderTitle(e.target.value)}
                      onKeyPress={handleInputKeyPressCreateButton}
                      onBlur={handleInputBlur}
                      mt={"0.2rem"}
                      size={theme.fontSizes.md}
                      c={theme.colors.dark[8]}
                      placeholder={DEFAULT_FOLDER_NAME}
                      className='inputfolder'
                    />
                </Flex>
              </Grid.Col>
            </Grid>
          )}
          <Grid columns={totalGrid}>
            <Grid.Col span={leftGrid}></Grid.Col>
            <Grid.Col span={centerGrid + rightGrid}>
              {storedFolders.length > 0 &&
              storedFolders.map((folder, index) => (
                <div key={index}>
                  <Flex
                    mt={"md"}
                    align={"center"}
                    onContextMenu={(event) => handleFolderRightClickEmpty(event, folder)}
                  >
                    <IconWrapper name='folder' className='allfundqueryicon' />
                    {newEmptyFolderNameDelete ? (
                      <TextInput
                        ref={inputRef}
                        ml={"0.2rem"}
                        size={theme.fontSizes.md}
                        c={theme.colors.dark[8]}
                        defaultValue={folder}
                        onChange={(event) => setNewEmptyFolderName(event.target.value)}
                        onKeyDown={handleKeyDownEmpty}
                        onBlur={handleBlurSaveEmpty}
                        className='inputfolder'
                        onFocus={handleFocus}
                        autoFocus
                      />
                    ) : (
                      <TextInput
                        ml={"0.2rem"}
                        size={theme.fontSizes.md}
                        c={theme.colors.dark[8]}
                        disabled
                        className='dropfolder'
                        placeholder={folder}
                        onDrop={handleDrop}
                        onDragOver={(event) => event.preventDefault()}
                        autoFocus
                      />
                    )}
                  </Flex>
                </div>
              ))}

              {localStorage.getItem("deletedFolderName") &&
              !checkDelete.includes(localStorage.getItem("deletedFolderName") as string) &&
              deletedName !== "default" && (
                <div className='folder-wrapper'>
                  <Flex
                    align={"center"}
                    mt={"0.2rem"}
                    onContextMenu={(event) => handleInitialRightClickEmpty(event, deletedName)}
                  >
                    <IconWrapper name='folder' className='allfundqueryicon' />
                    <FolderTextInput
                      newFolderNameDelete={newFolderNameDelete}
                      newFolderName={newFolderName}
                      setNewFolderName={setNewFolderName}
                      handleDropLocalStorage={handleDropLocalStorage}
                      handleKeyDownDelete={handleKeyDownDelete}
                      handleBlurSaveDelete={handleBlurSaveDelete}
                      handleFocus={handleFocus}
                    />
                  </Flex>
                </div>
              )}

              {!creatingFolder && renderFolders()}
              <div ref={contextMenuRef}>
                <ContextMenu
                  {...contextMenu}
                  currentName={
                    contextMenu.docId
                      ? data
                        .flatMap((item) => item.documents)
                        .find((doc) => doc.uuid === contextMenu.docId)?.name || ""
                      : contextMenu.folderId
                        ? localStorage.getItem(contextMenu.folderId) || contextMenu.folderId
                        : ""
                  }
                  onRename={(docId, currentName) => handleRename(docId, currentName)}
                  onRenameFolder={(folderId, currentName) =>
                    handleRenameFolder(folderId, currentName)
                  }
                  onRenameEmptyFolder={(folderTitle: string, newName: string) =>
                    handleRenameEmptyFolder(folderTitle, newName)
                  }
                  onDelete={handleDelete}
                  onDeleteFolder={handleDeleteFolder}
                  onDeleteEmptyFolder={() => clearFolderFromLocalStorage("folderName")}
                  onRenameInitialFolder={(folderEmpty: string, currentName: string) =>
                    handleRenameEmpty(folderEmpty, currentName)
                  }
                  onDeleteInitialFolder={() => clearFolderFromLocalStorage("deletedFolderName")}
                  folderEmpty={deletedName}
                />
              </div>
            </Grid.Col>
          </Grid>
        </Collapse>
        <Collapse key="form" in={!isPresetOpen} transitionTimingFunction={isPresetOpen ? "ease-out" : "ease-in"}>
          <Grid columns={totalGrid}>
            <Grid.Col span={leftGrid/3}></Grid.Col>
            <Grid.Col span={totalGrid - leftGrid/3}>
              <QueryForm {...{
                fundGroup, setFundGroup,
                fundId, setFundId,
                assetIdType, setAssetIdType,
                assetId, setAssetId,
                selectedTypeOfData, setSelectedTypeOfData,
                settled, setSettled,
                total, setTotal,
                onLoan, setOnLoan,
                selectedFromDate, setSelectedFromDate,
                selectedToDate, setSelectedToDate,
                toggleSavePresetModal,
                handleSubmit,
                presetName, setPresetName,
                isSavePresetModalOpen, handleSave,
                selectedFolderName, setSelectedFolderName
              }} />
            </Grid.Col>
          </Grid>
        </Collapse>
      </Collapse>
    </Flex>
  );
}
