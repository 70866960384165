import React, { useMemo, useState, useEffect, useCallback, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, GridOptions, ValueGetterParams } from 'ag-grid-community';
import { Flex, Text, useMantineTheme, Button, Card, Modal, Grid, TextInput, SelectProps, Group, Box } from '@mantine/core';
import { IconCirclePlus } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { services } from '../../../services';
import "./AdminPage.css";
import { KeyValuePairs, UniqueDescription } from '../../../types';
import PageTitle from '../../../components/Common/PageTitle';
import RolesTable from '../../../components/AdminPage/Roles';
import FundGroup from '../../../components/AdminPage/FundGroup/FundGroup';
import CustomNoRowsOverlay from '../../../components/Common/customNoRowsOverlay';
import { useDisclosure } from '@mantine/hooks';
import { roles } from '../../../constants';
import DeleteModalUser from '../../../components/AdminPage/DeleteModalUser';
import AdminTableHeader from '../../../components/AdminPage/AdminTableHeader';
import CustomButton from '../../../components/Common/Buttons/CustomButton';
import Select from '../../../components/Common/Select/Select';
import { handleMainContentClickUtil } from '../../../utils/handleMainContentClick';
import { useDeselectAllOnRowUnselect } from '../../../utils/useDeselectAllOnRowUnselect';

const Users = () => {
    const { t, i18n } = useTranslation();
    const languageKey = i18n.language;
    const theme = useMantineTheme();
    const [userIdTest, setUserIdTest] = useState('');
    const [userId, setUserId] = useState<string | null>('');
    const [roleID, setRoleID] = useState<string>('');
    const [apiData, setApiData] = useState<any[]>([]);
    const [editMode, setEditMode] = useState(false);
    const [userToDelete, setUserToDelete] = useState<{ id: string, username: string, roleID: string } | null>(null);
    const [isRowSelected, setIsRowSelected] = useState(false);
    const [roleCounts, setRoleCounts] = useState<{ [key: string]: number }>({});
    const [fundGroupCounts, setfundGroupCounts] = useState<{ [key: string]: number }>({});
    const [roleAndFundGroupCounts, setRoleAndFundGroupCounts] = useState<any>({});
    const [modalType, setModalType] = useState<null | 'create' | 'update' | 'delete'>(null);
    const [modalOpened, { toggle: toggleModal }] = useDisclosure(false);
    const [uniqueDescriptions, setUniqueDescriptions] = useState<UniqueDescription[]>([]);

    const initialNewUserData = {
        username: '',
        email: '',
        firstName: '',
        lastName: '',
        enabled: true,
        credentials: [{
            type: 'password',
            value: 'test',
            temporary: false
        }],
        roles: {}
    };

    const [uniqueFundGroup, setUniqueFundGroup] = useState<string[]>([]);

    const optionsRole = (uniqueDescriptions.length > 0 ? uniqueDescriptions : [{ description: '', role: '' }])
        .sort((a, b) => a.description.localeCompare(b.description))
        .map(item => ({
            value: item.role,
            label: item.description,
        }));

    const optionsFundGroup = (uniqueFundGroup.length > 0 ? uniqueFundGroup : [''])
        .sort((a, b) => a.localeCompare(b))
        .map(fundGroup => ({
            value: fundGroup,
            label: fundGroup,
        }));

    const handleUniqueDescriptionsChange = (descriptions: any) => {
        setUniqueDescriptions(descriptions);
    };

    const handleUniqueFundGroupChange = (uniqueFundGroup: string[]) => {
        setUniqueFundGroup(uniqueFundGroup);
    };

    const handleModalToggle = (type: 'create' | 'update' | 'delete') => {
        if (modalType === type && modalOpened) {
            setModalType(null);
            toggleModal();
        } else {
            setModalType(type);
            if (!modalOpened) toggleModal();
        }
    };

    const [newUserData, setNewUserData] = useState(initialNewUserData);

    const [selectedRole, setSelectedRole] = useState("");
    const [selectedFundGroup, setSelectedFundGroup] = useState(""); // Initialize with 'All-Index' as default

    const handleRoleChange = (value: string | null) => {
        if (value) {
            setSelectedRole(value);
        }
    };

    const newFundGroupRole = {
        "fund_group": " ",
        "role": " "
    }
    const [fundGroupRole, setFundGroupRole] = useState([newFundGroupRole]);

    const addNewFundGroupRole = () => {
        setFundGroupRole([...fundGroupRole, newFundGroupRole]);
    };

    const fetchData = async () => {
        try {
            const response = await axios.get(`${services.GET_FUND_DETAILS}`);
            const data = response.data;
            // Map fetched data to match ag-grid format
            const formattedData = Array.isArray(data) ? data.map((user: { username: any; firstName: string; lastName: string; email: any; role: any; fund_group: any; id: any; roleDescription: any; user_id: any; }) => ({
                user_id: user.username ?? '',
                username: `${user.firstName ?? ''} ${user.lastName ?? ''}`,
                email: user.email != "null" ? user.email : '',
                role: user.roleDescription ?? '',
                fund_group: user.fund_group ?? '',
                id: user.id ?? '',
                role_id: user.user_id ?? '',
            })) : [];
            setApiData(formattedData);
            console.log("formattedData", formattedData);

            const fundGroupUserCounter: any = {};
            const fundGroupRoleCounter: any = {};
            const uniqueRolesCount: any = {};
            formattedData.forEach((record: any) => {
                const { fund_group, role } = record;
                fundGroupUserCounter[fund_group] = fundGroupUserCounter[fund_group] ? fundGroupUserCounter[fund_group] + 1 : 1;
                if (!fundGroupRoleCounter[fund_group]) {
                    fundGroupRoleCounter[fund_group] = new Set();
                }
                fundGroupRoleCounter[fund_group].add(role);
                uniqueRolesCount[role] = uniqueRolesCount[role] ? uniqueRolesCount[role] + 1 : 1;
            })

            setRoleCounts(uniqueRolesCount);
            setfundGroupCounts(fundGroupUserCounter);
            setRoleAndFundGroupCounts(fundGroupRoleCounter);

        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            fetchData();
        }, 500);
        return () => clearTimeout(timer);
    }, [editMode, userIdTest]);


    const colDefs: ColDef[] = [
        { headerName: t("USER_ID"), field: "user_id", flex: 1, sort: 'asc' },
        { headerName: t("USER_NAME"), field: "username", flex: 1 },
        { headerName: t("EMAIL"), field: "email", flex: 1 },
        {
            headerName: t("ROLE"), field: "role", flex: 1,
            cellRenderer: (params: ValueGetterParams) => {
                if (Array.isArray(params.data.role)) {
                    const roles = params.data.role.join(', ');
                    return <div>{t(roles)}</div>;
                } else {
                    return <div>{t(params.data.role)}</div>;
                }
            }
        },
        {
            headerName: t("FUND_GROUP"), field: "fund_group", flex: 1,
            cellRenderer: (params: ValueGetterParams) => {
                if (Array.isArray(params.data.fund_group)) {
                    const fundGroups = params.data.fund_group.join(', ');
                    return <div>{fundGroups}</div>;
                } else {
                    return <div>{params.data.fund_group}</div>;
                }
            }
        },
    ];

    const customheight = () => {
        if (apiData.length > 1) {
            const heightInRem = 2.1 + (apiData.length * 1.85);
            return `${heightInRem}rem`;
        } else {
            return "3.8rem";
        }
    };

    const rowSelectionType = 'single'
    const rowHeight = 30;


    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setNewUserData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSelectionChanged = (event: any) => {
        setIsRowSelected(event.api.getSelectedNodes().length > 0);
    };

    const handleDeleteUser = () => {
        if (userToDelete) {

            axios.delete(services.UPDATE_DELETE_USER(userToDelete.roleID), { data: { id: userToDelete.id } })
                .then(response => {
                    axios.get(`${services.GET_FUND_DETAILS}`)
                        .then(response => {
                            const data = response.data;
                            const formattedData = data.map((user: any) => ({
                                user_id: user.username,
                                username: user.firstName + " " + user.lastName,
                                email: user.email,
                                role: user.role,
                                fund_group: user.fund_group,
                                id: user.id,
                            }));
                            setApiData(formattedData);
                            setTimeout(() => {
                                fetchData();
                                setIsRowSelected(false)
                            }, 500);
                        })
                        .catch(error => {
                            console.error(error);
                        });
                })
                .catch(error => {
                    console.error('Error deleting user role:', error);

                })
                .finally(() => {
                    handleModalToggle('delete')
                });
        }
    };

    const handleDeleteClick = () => {
        const gridApi = gridApiRef.current;
        const selectedNodes = gridApi.getSelectedNodes();

        if (selectedNodes.length > 0) {
            const selectedNode = selectedNodes[0];
            const userId = selectedNode.data.id;
            const username = selectedNode.data.username;
            const roleID = selectedNode.data.role_id;

            setUserToDelete({ id: userId, username: username, roleID: roleID });
            handleModalToggle('delete')
        }
    };

    const handleFundGroupChange = (value: string | null) => {
        setSelectedFundGroup(value || "");

        setNewUserData(prevState => ({
            ...prevState,
            roles: {
                ...prevState.roles,
                [selectedRole]: value ? [value] : []
            }
        }));
    };

    const FundGroupRole = ({ }: { index: number, item: KeyValuePairs }) => {
        return (
            <Flex direction={'row'} mt={'md'} justify={'space-between'} align={'center'} pl={"md"}>
                <Flex justify={'center'} align={'center'}>
                    <Text>{t("FUND_GROUP")}</Text>
                    <div className='textInput'>
                        <Select
                            value={selectedFundGroup}
                            onChange={handleFundGroupChange}
                            data={optionsFundGroup}
                            className='textInput'
                            ml={'xl'}
                        />
                    </div>
                </Flex>

                <Flex ml={'xl'} justify={'center'} align={'center'}>
                    <Text>{t("ROLE")}</Text>
                    <div className='textInput'>
                        <Select
                            value={selectedRole}
                            onChange={handleRoleChange}
                            data={optionsRole}
                            className='textInput'
                            ml={'md'}
                            mr={'-0.2rem'}
                        />
                    </div>
                </Flex>
            </Flex>
        );
    };

    const handleEditUser = () => {
        const gridApi = gridApiRef.current;
        console.log("gridApi", gridApi)
        const selectedNodes = gridApi.getSelectedNodes();

        if (selectedNodes.length > 0) {
            const selectedNode = selectedNodes[0];
            const selectedUser = selectedNode.data;
            console.log({ selectedUser })

            setNewUserData({
                username: selectedUser.user_id,
                email: selectedUser.email,
                firstName: selectedUser.username.split(' ')[0],
                lastName: selectedUser.username.split(' ')[1],
                enabled: true,
                credentials: [{
                    type: 'password',
                    value: 'test',
                    temporary: false
                }],
                roles: {
                    [selectedUser.role]: selectedUser.fund_group[0]
                }
            });
            handleRoleChange(roles.find(row => row.label === selectedUser.role)?.key || null);
            setSelectedFundGroup(selectedUser.fund_group[0]);
            setUserId(selectedUser.id)
            setRoleID(selectedUser.role_id)
            setEditMode(true);
            handleModalToggle('update')
        }
    };

    const handleCreateUser = async () => {
        const rolesObject = {
            [selectedRole]: selectedFundGroup ? [selectedFundGroup] : []
        };

        const newUser = {
            ...newUserData,
            roles: rolesObject
        };

        if (editMode) {
            console.log({ selectedFundGroup });

            axios.put(`${services.UPDATE_DELETE_USER(roleID)}`, { id: userId, role: selectedRole, fundGroups: selectedFundGroup ? [selectedFundGroup] : [] })
                .catch(error => {
                    console.error('Error updating user:', error);
                });
            handleModalToggle('update')
            setTimeout(() => {
                fetchData();
                setIsRowSelected(false)
            }, 500);

        } else {
            axios.post(`${services.CREATE_USER}`, newUser)
                .then(response => {
                    const createdUser = response.data;
                    setApiData([...apiData, createdUser]);
                    handleModalToggle('create')
                    setTimeout(() => {
                        fetchData();
                    }, 500);

                })
                .catch(error => {
                    console.error('Error creating user:', error);
                });
        }
    };

    const gridApiRef = useRef<any>(null);
    useDeselectAllOnRowUnselect(gridApiRef, isRowSelected);

    const onGridReady = (params: { api: any; }) => {
        gridApiRef.current = params.api;
    };

    const noRowsOverlayComponentParams = useMemo(() => {
        return {
            noRowsMessageFunc: () => `- ${t("NO_USERS_HERE")} -`,
        };
    }, []);

    const gridOptions: GridOptions = {
        suppressRowHoverHighlight: true,
    };

    const resetCreateUser = () => {
        setEditMode(false);
        handleModalToggle('create');
        setNewUserData(initialNewUserData);
        setSelectedFundGroup('');
        setSelectedRole('');
    }

    const refreshFundGroupRole = () => {
        setFundGroupRole([...fundGroupRole]);
    };

    useEffect(() => {
        refreshFundGroupRole();
    }, []);

    const mainContentRef = useRef<HTMLDivElement>(null);

    const handleMainContentClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
        handleMainContentClickUtil(e, mainContentRef, setIsRowSelected);
    };

    return (
        <>
            <PageTitle TITLE='Users' />
            <div className='main-content-users' ref={mainContentRef}
                onClick={handleMainContentClick}>
                <Box mr={'2.5rem'}>
                    <AdminTableHeader isRowSelected={isRowSelected} handleEditUser={handleEditUser} handleDeleteClick={handleDeleteClick} resetCreateUser={resetCreateUser} headerTitle='ACCOUNTS' createTitle='CREATE_USER_ACCOUNT' />
                </Box>
                <Modal opened={modalOpened && modalType === (editMode ? 'update' : 'create')} onClose={() => handleModalToggle(editMode ? 'update' : 'create')} size="45.4375rem" centered withCloseButton={false} radius={'30px'} className='modal' >
                    <Card bg={theme.colors.grey[2]} p={"xl"}>
                        <Text fw={700} size='lg' ta={'center'} mt={"-17px"} mb={theme.spacing.xs}> {editMode ? t("EDIT_USER_ACCOUNT") : t("CREATE_USER_NEW_ACCOUNT")}</Text>
                        <Grid mt={"lg"}>
                            <Grid.Col span={2} >
                                <Text pl={"1rem"} mt={"xs"} size='md'>{t("USER_ID")}</Text>
                            </Grid.Col>
                            <Grid.Col span={10}>
                                <TextInput
                                    type="text"
                                    name="username"
                                    w={"33.625rem"}
                                    value={newUserData.username}
                                    onChange={handleInputChange}
                                    className='textInput'
                                    disabled={editMode}
                                />
                            </Grid.Col>
                        </Grid>

                        <Grid mt={"md"}>
                            <Grid.Col span={2} >
                                <Text pl={"1rem"} mt={"xs"} size='md'>{t("EMAIL")}</Text>
                            </Grid.Col>
                            <Grid.Col span={10}>
                                <TextInput
                                    type="email"
                                    name="email"
                                    w={"33.625rem"}
                                    value={newUserData.email}
                                    onChange={handleInputChange}
                                    className='textInput'
                                    disabled={editMode}
                                />
                            </Grid.Col>
                        </Grid>

                        <Grid mt={"md"}>
                            <Grid.Col span={2} >
                                <Text pl={"1rem"} mt={"xs"} size='md'>{t("FIRST_NAME")}</Text>
                            </Grid.Col>
                            <Grid.Col span={10}>
                                <TextInput
                                    type="text"
                                    name="firstName"
                                    w={"33.625rem"}
                                    value={newUserData.firstName}
                                    onChange={handleInputChange}
                                    className='textInput'
                                    disabled={editMode}
                                />
                            </Grid.Col>
                        </Grid>

                        <Grid mt={"md"}>
                            <Grid.Col span={2} >
                                <Text pl={"1rem"} mt={"xs"} size='md'>{t("LAST_NAME")}</Text>
                            </Grid.Col>
                            <Grid.Col span={10}>
                                <TextInput
                                    type="text"
                                    name="lastName"
                                    w={"33.625rem"}
                                    value={newUserData.lastName}
                                    onChange={handleInputChange}
                                    className='textInput'
                                    disabled={editMode}
                                />
                            </Grid.Col>
                        </Grid>



                        {fundGroupRole.map((item, idx) =>
                            <FundGroupRole key={idx} index={idx} item={item} />
                        )}

                        <Button variant="transparent"
                            mt={'md'}
                            ml={'5.5rem'}
                            c="dark"
                            onClick={addNewFundGroupRole}
                            justify='flex-start'
                            ta={"center"}
                        >
                            <IconCirclePlus stroke={1.5} size={"1.2rem"} />
                            <Text ms="sm" size='md'>{t("ADD_GROUPS_ROLES")}</Text>
                        </Button>

                        <Flex justify="flex-end" pb={"1.8rem"} mt={'sm'}>
                            <CustomButton
                                variant="cancel"
                                onClick={() => {
                                    handleModalToggle(editMode ? 'update' : 'create');
                                    setFundGroupRole([newFundGroupRole]);
                                }}
                                children={"CANCEL"}
                            />
                            <CustomButton variant="submit" onClick={handleCreateUser} children={editMode ? "SET" : "CREATE"} />
                        </Flex>
                    </Card>
                </Modal>


                <DeleteModalUser
                    opened={modalOpened && modalType === 'delete'}
                    onClose={() => handleModalToggle('delete')}
                    modalType={modalType}
                    TITLE='DELETE_USER_ACCOUNT'
                    message='CONFIRM_DELETE_MESSAGE'
                    onSubmit={handleDeleteUser}
                />

                <div className='action-item ag-theme-quartz'
                    style={{
                        height: customheight(),
                        width: "95%",
                        maxHeight: "23.125rem",
                        minHeight: "3.8rem",
                        marginTop: "0.7rem"

                    }}
                >
                    <AgGridReact
                        key={languageKey}
                        rowData={apiData}
                        columnDefs={colDefs}
                        rowSelection={rowSelectionType}
                        rowHeight={rowHeight}
                        rowMultiSelectWithClick={true}
                        onGridReady={onGridReady}
                        onSelectionChanged={handleSelectionChanged}
                        noRowsOverlayComponent={CustomNoRowsOverlay}
                        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                        gridOptions={gridOptions}
                    />
                </div>

                <Box maw={'48rem'}>
                    <RolesTable apiData={apiData} roleCounts={roleCounts} onUniqueDescriptionsChange={handleUniqueDescriptionsChange} />
                    <Text id='selectedRows'></Text>

                    <FundGroup roleCounts={roleCounts} fundGroupCounts={fundGroupCounts} roleAndFundGroupCounts={roleAndFundGroupCounts} onUniqueFundGroup={handleUniqueFundGroupChange} />
                </Box>
            </div>
        </>
    );
}

export default Users;