import React from 'react';
export const handleRenameCommon = (
    id: string,
    currentName: string,
    setId: React.Dispatch<React.SetStateAction<string | null>>,
    setNewName: React.Dispatch<React.SetStateAction<string>>,
    setContextMenu: React.Dispatch<React.SetStateAction<{
        visible: boolean;
        x: number;
        y: number;
        docId: string | null;
        folderId: string | null;
        folderTitle: string | null;
    }>>
) => {
    setId(id);
    setNewName(currentName);
    setContextMenu(prevContextMenu => ({
        ...prevContextMenu,
        visible: false
    }));
};

export type RenameParams = {
    newName?: string;
    folderKey: string;
    setNameDelete: React.Dispatch<React.SetStateAction<boolean>>;
    setContextMenuVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

export const handleRenameEmptyFolders = ({
    newName,
    folderKey,
    setNameDelete,
    setContextMenuVisible,

}: RenameParams) => {
    setNameDelete(true);
    setContextMenuVisible(false);

    if (newName) {
        localStorage.removeItem(folderKey);
        localStorage.setItem(folderKey, newName);
    }
};

export const handleSave = (newFolderName: string, localStorageKey: string, setFolderNameDelete: (value: boolean) => void) => {
    const currentFolderName = localStorage.getItem(localStorageKey);
    if (newFolderName !== currentFolderName) {
        console.log('Saving value:', newFolderName);
        localStorage.setItem(localStorageKey, newFolderName);
        setFolderNameDelete(false);
    } else {
        console.log('Folder name is the same, not saving.');
        setFolderNameDelete(false);
    }
};

// Utility function to handle event logic
export const handleEvent = (
    event: { key: string },
    newFolderName: string,
    localStorageKey: string,
    setFolderNameDelete: (value: boolean) => void
) => {
    if (event.key === 'Enter') {
        handleSave(newFolderName, localStorageKey, setFolderNameDelete);
    }
};

export const handleRenameFolders = (
    nameKey: string,
    newName: string,
    setDeleteState: (state: boolean) => void,
    setContextMenuVisible: (visible: boolean) => void,
    fetchData?: () => void
) => {
    setDeleteState(true);
    setContextMenuVisible(false);

    if (newName) {
        localStorage.removeItem(nameKey);
        localStorage.setItem(nameKey, newName);
        if (fetchData) fetchData();
    }
};



export interface ContextMenuProps {
    visible: boolean;
    x: number;
    y: number;
    docId: string | null;
    folderId: string | null;
    folderTitle: string | null;
    folderEmpty: string | null;
}

export interface ContextMenuProps {
    visible: boolean;
    x: number;
    y: number;
    docId: string | null;
    folderId: string | null;
    folderTitle: string | null;
    folderEmpty: string | null;
}

export type ContextMenuState = {
    visible: boolean;
    x: number;
    y: number;
    docId: string | null;
    folderId: string | null;
    folderTitle: string | null;
    folderEmpty: string | null;
};

export const updateContextMenu = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    setContextMenu: React.Dispatch<React.SetStateAction<ContextMenuState>>,
    options: Partial<Omit<ContextMenuState, 'visible' | 'x' | 'y'>>
) => {
    event.preventDefault();
    setContextMenu({
        visible: true,
        x: event.clientX,
        y: event.clientY - 60,
        docId: options.docId ?? null,
        folderId: options.folderId ?? null,
        folderTitle: options.folderTitle ?? null,
        folderEmpty: options.folderEmpty ?? null,
    });
};




