import axios from "axios";
import { services } from "../../../services";
import { format, isToday, isYesterday, isTomorrow, isValid } from 'date-fns';
import { DATE_SPEC_TODAY, DATE_SPEC_TOMORROW, DATE_SPEC_YESTERDAY, getSpecialDate } from '../../../constants';
import { v4 as uuid_v4 } from "uuid";
import { Document } from '../../../types';

const mapData = (data: any[], endpoint: string) => {
  console.log("Mapping data for endpoint:", endpoint);
  if (!Array.isArray(data)) {
    console.error("Invalid data format. Expected an array.");
    return [];
  }
  return data.map((item: any, index: number) => {
    const mappedItem: any = {
      sr_no: index + 1,
    };
    if (endpoint === services.ASSETS) {
      const asset = item.asset;
      mappedItem.fund_id = asset.fund_id;
      mappedItem.as_of_date = asset.as_of_date;
      mappedItem.asset_id = asset.asset_key.asset_id;
      mappedItem.bloomberg_ticker = asset.asset_key.bloomberg_ticker;
      mappedItem.currency = asset.asset_attributes.currency;
      mappedItem.price_date = asset.asset_prices.price_date;
      mappedItem.price = asset.asset_prices.prices_for_price_type.open_price;
      mappedItem.average_daily_volume = asset.asset_prices.average_daily_volume;
      mappedItem.min_trade_size = asset.asset_prices.min_trade_size;
      mappedItem.min_trade_increment = asset.asset_prices.min_trade_increment;
      mappedItem.source = asset.asset_prices.source;
      mappedItem.asset_group = asset.asset_group;
      mappedItem.asset_name = asset.asset_attributes.asset_name;
      mappedItem.issue_country = asset.asset_attributes.issue_country;
      mappedItem.trading_country = asset.asset_attributes.trading_country;
      mappedItem.risk_country = asset.asset_attributes.risk_country;
      mappedItem.sector = asset.asset_attributes.sector;
      mappedItem.total_quantity = asset.equity.total_quantity;
      mappedItem.traded_quantity = asset.equity.traded_quantity;
      mappedItem.settled_quantity = asset.equity.settled_quantity;
      mappedItem.substituted_in_quantity = asset.equity.substituted_in_quantity;
      mappedItem.substituted_out_quantity = asset.equity.substituted_out_quantity;
      mappedItem.adjusted_quantity = asset.equity.adjusted_quantity;
      mappedItem.indirectly_held_quantity = asset.equity.indirectly_held_quantity;
      mappedItem.on_loan_quantity = asset.equity.on_loan_quantity;
      mappedItem.approved_orders_quantity = asset.equity.approved_orders_quantity;
      mappedItem.proposed_orders_quantity = asset.equity.proposed_orders_quantity;
    } else if (endpoint === services.ORDERS) {
      const order = item.orders;
      mappedItem.fund_id = order.fund_id;
      mappedItem.asset_id = order.asset_key.asset_id;
      mappedItem.bloomberg_ticker = order.asset_key.bloomberg_ticker;
      mappedItem.asset_type = order.asset_type;
      mappedItem.market = order.market;
      mappedItem.asset_name = order.asset_name;
      mappedItem.transaction_type = order.transaction_type;
      mappedItem.quantity = order.quantity;
      mappedItem.currency = order.currency;
      mappedItem.amount = order.amount;
      mappedItem.order_motivation = order.order_motivation;
      mappedItem.trading_benchmark = order.trading_benchmark;
      mappedItem.benchmark_date = order.benchmark_date;
      mappedItem.trade_date = order.trade_date;
      mappedItem.settle_date_offset = order.settle_date_offset;
      mappedItem.broker_identifier = order.broker_identifier;
      mappedItem.execution_instruction = order.execution_instruction;
      mappedItem.limit_type = order.limit_type;
      mappedItem.limit_value = order.limit_value;
      mappedItem.stop_type = order.stop_type;
      mappedItem.stop_value = order.stop_value;
      mappedItem.filled_quantity = order.filled_quantity;
      mappedItem.filled_amount = order.filled_amount;
      mappedItem.settlement_currency = order.settlement_currency;
      mappedItem.price_currency = order.price_currency;
      mappedItem.basket_name = order.basket_name;
      mappedItem.comments = order.comments;
      mappedItem.order_status = order.order_status;
      mappedItem.create_time = order.create_time;
      mappedItem.created_by = order.created_by;
      mappedItem.last_updated_time = order.last_updated_time;
      mappedItem.last_updated_by = order.last_updated_by;
      mappedItem.transaction_id = order.transaction_id;
      mappedItem.order_type = order.order_type;
    } else if (endpoint === services.BENCHMARK) {
      const benchmarkChange = item.benchmark_change;
      mappedItem.fund_id = item.fund_id;
      mappedItem.benchmark_id = item.benchmark_id;
      mappedItem.fund_currency = item.fund_currency;
      mappedItem.fund_group = item.fund_group;
      mappedItem.fund_name = item.fund_name;
      mappedItem.primary_fund_manager = item.primary_fund_manager;
      mappedItem.benchmark_identifier = benchmarkChange.benchmark_identifier;
      mappedItem.change_type = benchmarkChange.change_type;
      mappedItem.share_change = benchmarkChange.share_change;
      mappedItem.price = benchmarkChange.price;
      mappedItem.currency = benchmarkChange.currency;
      mappedItem.market_cap_change = benchmarkChange.market_cap_change;
      mappedItem.pct_change = benchmarkChange.pct_change;
      mappedItem.description = benchmarkChange.description;
      mappedItem.change_id = benchmarkChange.change_id;
      mappedItem.source_id = benchmarkChange.source_id;
      mappedItem.vendor_id = benchmarkChange.vendor_id;
      mappedItem.effective_date = benchmarkChange.effective_date;
      mappedItem.asset_id = benchmarkChange.asset_key.asset_id;
      mappedItem.bloomberg_ticker = benchmarkChange.asset_key.bloomberg_ticker;
    }

    return mappedItem;
  });
};

const mapDatas = (data: any[], endpoint: string) => {
  const mappedData: any[] = [];

  data.forEach((item: any) => {
    const { fund_id, fund_group, targets } = item;

    if (targets && Array.isArray(targets)) {
      targets.forEach((target: any, index: number) => {
        console.log("Checking target:", target);

        const mappedItem: any = {
          sr_no: mappedData.length + 1,
          fund_id,
          type: target.type || null,
          target_category: target.target_category || null,
          lower_threshold: target.lower_threshold || null,
          upper_threshold: target.upper_threshold || null,
          target_value: target.hasOwnProperty("target_value") ? target.target_value : null,
        };

        console.log("Mapped Item:", mappedItem);

        mappedData.push(mappedItem);
      });
    }
  });

  return mappedData;
};

const savePreset = async (folderId: string | null = null, uuid: string, { 
  presetName, selectedFolderName, 
  fundGroup, fundId, 
  assetId, assetIdType, selectedFromDate, selectedToDate, settled, 
  onLoan, total, selectedTypeOfData
} : any) => {
  
  const formData: any = {
    name: presetName,
    folder_name: selectedFolderName,
    fund_group: fundGroup,
    fund_id: fundId,
    asset_id: assetId,
    asset_id_type: assetIdType,
    start_date: selectedFromDate,
    end_date: selectedToDate,
    settled,
    on_loan: onLoan,
    total,
    data_type: selectedTypeOfData,

  };
  
  try {
    const response = await axios.post(`${services.GET_PRESETS}/${uuid}`, JSON.stringify(formData), {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    Promise.resolve(response.data.data)
      .then((data) => {
        if (data === null) {
          throw new Error(response.data.message);
        }
        console.log("API Response:", response.data);

        if (!folderId) {
          const fileData = {
            uuid,
            ...formData,
          };

          const unassignedFilesString = localStorage.getItem('unassignedFiles');
          const unassignedFiles = unassignedFilesString ? JSON.parse(unassignedFilesString) : [];
          unassignedFiles.push(fileData);
          localStorage.setItem('unassignedFiles', JSON.stringify(unassignedFiles));
          localStorage.setItem('presetName', JSON.stringify(data));
        }
        // window.location.reload();
      });
  } catch (error: any) {
    throw new Error(error?.response?.status || error.message)
  }
};

const formatDate = (date: Date | null) => {
  if (date && isValid(date)) {
    if (isToday(date)) return "DATE_SPEC_TODAY";
    if (isYesterday(date)) return "DATE_SPEC_YESTERDAY";
    if (isTomorrow(date)) return "DATE_SPEC_TOMORROW";
    return format(date, "yyyy-MM-dd");
  }
  return "";
};

const processDates = (data: any) => {
  return data.map((item: any) => {
    const processedDocuments = item.documents.map((doc: any) => {
      let processedStartDate: Date;
      let processedEndDate: Date;

      // Check and process start_date
      if (doc.start_date === DATE_SPEC_TODAY || doc.start_date === DATE_SPEC_YESTERDAY || doc.start_date === DATE_SPEC_TOMORROW) {
        processedStartDate = getSpecialDate(doc.start_date);
      } else {
        processedStartDate = new Date(doc.start_date);
      }

      // Check and process end_date
      if (doc.end_date === DATE_SPEC_TODAY || doc.end_date === DATE_SPEC_YESTERDAY || doc.end_date === DATE_SPEC_TOMORROW) {
        processedEndDate = getSpecialDate(doc.end_date);
      } else {
        processedEndDate = new Date(doc.end_date);
      }

      return {
        ...doc,
        start_date: processedStartDate,
        end_date: processedEndDate,
      };
    });
    return {
      ...item,
      documents: processedDocuments,
    };
  });
};

const createFolder = async (folderTitle: string) => {
  try {
    const uuid = uuid_v4();
    const newDocument: Document = {
      uuid: uuid,
      name: folderTitle,
      asset_id: "",
      asset_id_type: "",
      data_type: "",
      end_date: "",
      fund_group: "",
      fund_id: "",
      on_loan: false,
      settled: false,
      start_date: "",
      total: 0,
      folder_name: folderTitle,
    };
    await axios.post(services.GET_PRESETS, newDocument);
  } catch (error) {
    console.error("Error creating folder:", error);
  }
}

const renameDocument = async (docId: string, newName: string) => {
  try {
    await axios.post(`${services.GET_PRESETS}/${docId}`, { name: newName });
    console.log("Document renamed:", docId, newName);
    return true;
  } catch (error) {
    console.error("Error renaming document:", error);
    return false;
  }
};

const clearFolderFromLocalStorage = (key: string) => {
  localStorage.removeItem(key);
  console.log(`Cleared ${key} from localStorage`);
  window.location.reload();
};

export {
  mapData,
  mapDatas,
  savePreset,
  formatDate,
  processDates,
  createFolder,
  renameDocument,
  clearFolderFromLocalStorage,
}