export const options = {
  cashflowMode: [
    { value: "MARKET_ON_OPEN", label: "Market On Open" },
    { value: "MARKET_ON_CLOSE", label: "Market On Close" },
  ],
  initIndex: [
		{ value: "all_index", label: "All-Index"},
	],
  recIndex: [
		{ value: "all_index", label: "All-Index" },
	],
  benchmark: [
    { value: "sp500", label: "SP500" },
    { value: "msci_acwi", label: "MSCI_ACWI" },
    { value: "msci_usa", label: "MSCI_USA" },
    { value: "msci_usa_large_cap", label: "MSCI_USA_LARGE_CAP" },
    { value: "msci_apac", label: "MSCI_APAC" },
    { value: "msci_europe", label: "MSCI_EUROPE" },
    { value: "msci_india", label: "MSCI_INDIA" },
    { value: "msci_japan", label: "MSCI_JAPAN" },
    { value: "msci_eafe", label: "MSCI_EAFE" },
    { value: "msci_em", label: "MSCI_EM" },
    { value: "msci_world", label: "MSCI_WORLD" },
    { value: "msci_hungary", label: "MSCI_HUNGARY" },
  ],
  marketData: [
    { value: "fx_rates", label: "FX Rates" },
    { value: "asset_attributes", label: "Asset Attributes" },
    { value: "equity_prices", label: "Equity Prices" },
    { value: "futures_prices", label: "Futures Prices" },
  ],
}