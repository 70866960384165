export const getCurrentUTCDate = (): string => {
  const myDate = new Date();
  myDate.setUTCDate(myDate.getUTCDate()); 

  const year = myDate.getUTCFullYear();
  const month = String(myDate.getUTCMonth() + 1).padStart(2, '0'); 
  const day = String(myDate.getUTCDate()).padStart(2, '0'); 

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};
