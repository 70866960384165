import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import PageTitle from "../../components/Common/PageTitle";
import { Box, useMantineTheme } from "@mantine/core";
import AnalysisOrderTab from "./AnalysisOrderTab";
import PositionSummary from "./PositionSummary";
import HoldingContentTab from "./HoldingContentTab";
import SegmentedControl from "./SegmentedControl";
import { useTranslation } from "react-i18next";

const FundData: React.FC = () => {
  const { t } = useTranslation();
  const { fund_id, target_mode } = useParams();
  const theme = useMantineTheme();
  const [activeTab, setActiveTab] = useState<string>('analysis');

  const tabs = [
    {
      label: t('ANALYSIS_&_ORDERS'),
      value: 'analysis',
      content: <AnalysisOrderTab />
    },
    {
      label: t('POSITION_SUMMARY'),
      value: 'position',
      content: <PositionSummary />
    },
    {
      label: t('DETAILED_HOLDINGS'),
      value: 'holdings',
      content: <HoldingContentTab />
    },
  ];

  // Create refs for each tab
  const tabRefs = useRef(tabs.map(() => React.createRef<HTMLDivElement>()));

  const controlRef = useRef<HTMLDivElement>(null);

  const handleTabChange = (value: string) => {
    setActiveTab(value);
  };

  useEffect(() => {
    const activeIndex = tabs.findIndex(tab => tab.value === activeTab);
    const activeSegmentRef = tabRefs.current[activeIndex]?.current;
    const { offsetWidth, offsetLeft } = activeSegmentRef || {};
    const { style } = controlRef.current || {};

    if (style && offsetWidth !== undefined && offsetLeft !== undefined) {
      style.setProperty("--highlight-width", `${offsetWidth}px`);
      style.setProperty("--highlight-x-pos", `${offsetLeft}px`);
    }
  }, [activeTab, tabs]);

  return (
    <>
      <PageTitle TITLE={fund_id} back />
      <Box>
        <div className={activeTab === 'analysis' ? "main-content-analysis" : "main-content"}>
          <div className={activeTab === 'analysis' ? "margin-segment" : ""}>
            <SegmentedControl
              name="fund-tabs"
              callback={handleTabChange}
              controlRef={controlRef}
              defaultIndex={0}
              segments={tabs.map((tab, index) => ({
                label: tab.label,
                value: tab.value,
                ref: tabRefs.current[index]
              }))}
            />
          </div>
          <Box ta="start">
            {tabs.find(tab => tab.value === activeTab)?.content}
          </Box>
        </div>
      </Box>
    </>
  );
};

export default FundData;