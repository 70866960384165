import React, { ReactNode, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Outlet, Navigate } from "react-router-dom";
import { AppShell, MantineProvider } from "@mantine/core";
import "@mantine/core/styles.css";
import LoginPage from "./pages/LoginPage/LoginPage";
import HomePage from "./pages/HomePage/HomePage";
import { RefreshTokenAuthBody } from "./types";
import { routes } from "./routes";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { services } from "./services";
import FundData from "./pages/AlgoJournal/FundData";
import Dashboard from "./pages/Dashboard/Dashboard";
import LeftNavbar from "./components/Leftnavbar/LeftNavbar";
import { AllFundQuery } from "./pages/AllFundQuery/AllFundQuery";
import { AllFundQuery as AllFundQueryOption2 } from "./pages/AllFundQuery/AllFundQueryOption2";
import { I18nextProvider, useTranslation } from "react-i18next";
import { PageNotFound } from "./pages/404Page.tsx/PageNotFound";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import { logout, setUser } from "./store/userSlice";
import OrderCompletion from "./pages/AlgoJournal/OrderCompletion";
import AllFundStatus from "./pages/AllFundStatus/AllFundStatus";
import CoacManagement from "./pages/CoacManagement";
import { isAdmin, isExpiredToken } from "./utils/validations";
import AdminNavbar from "./components/Leftnavbar/AdminNavbar";
import UsersPage from "./pages/AdminPages/Users/Users";
import FundPage from "./pages/AdminPages/Funds/FundPage";
import Preferences from "./pages/AdminPages/Preferences/Preferences";
import SoftwareUpdate from "./pages/AdminPages/SoftwareUpdates/SoftwareUpdate";
import Support from "./pages/AdminPages/Support/Support";
import theme from "./constants/theme";
import DemoStarter from "./pages/DemoStarter/DemoStarter";
import { getConfig } from "./utils/conversions";
import AccountPage from "./pages/Account/AccountPage";

const token = localStorage.getItem("athena_A");
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
axios.defaults.baseURL = "/";

const fetchNewAccessToken = async (refreshToken: string): Promise<any> => {
	try {
		const newInstance = axios.create({
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
			baseURL: "/",
		});
		const body: RefreshTokenAuthBody = {
			client_id: process.env.REACT_APP_CLIENT_ID!,
			client_secret: process.env.REACT_APP_CLIENT_SECRET!,
			grant_type: "refresh_token",
			refresh_token: refreshToken,
		};
		const res = await newInstance.post(services.GET_TOKENS, body);
		return res.data;
	} catch (err) {
		throw new Error(`error in fetchAccessToken ${err}`);
	}
};

let requestQueue: {
	resolve: (token: string) => void;
	reject: (error: any) => void;
}[] = []; 
let tokenRefreshPromise: "fetching" | null = null;

axios.interceptors.request.use(
	async (config) => {
		let token = localStorage.getItem("athena_A");
		let refreshToken = localStorage.getItem("athena_R");
		if (token && refreshToken) {
			if (isExpiredToken(token)) {
				if (!tokenRefreshPromise) {
					tokenRefreshPromise = "fetching";
					try {
						const res = await fetchNewAccessToken(refreshToken);
						config.headers.Authorization = `Bearer ${res.access_token}`;
						axios.defaults.headers.common["Authorization"] = `Bearer ${res.access_token}`;
						localStorage.setItem("athena_A", res.access_token);
						localStorage.setItem("athena_R", res.refresh_token);
						requestQueue.forEach((queuedRequest) => {
							queuedRequest.resolve(res.access_token);
						});
						requestQueue = [];
						tokenRefreshPromise = null;
					} catch (err) {
						localStorage.removeItem("athena_A");
						localStorage.removeItem("athena_R");
						delete axios.defaults.headers.common["Authorization"];
						requestQueue.forEach((queuedRequest) => {
							queuedRequest.reject(err);
						});
						requestQueue = [];
						tokenRefreshPromise = null;
						window.location.reload(); //todo- try navigate instead
					}
				} else {
					return new Promise((resolve, reject) => {
						requestQueue.push({
							resolve: (token: string) => {
								config.headers.Authorization = `Bearer ${token}`;
								resolve(config);
							},
							reject: (error: any) => {
								config.headers.Authorization = "";
								reject(error);
							},
						});
					});
				}
			}
		}
		return config;
	},
	(error) => {
		return Promise.reject(error.response);
	},
);

const AuthLayout = ({ children }: { children: ReactNode }) => {
	const { authenticated, user } = useAppSelector(state => state.user);

	if (authenticated)
		return (
			<AppShell navbar={{ width: 201, breakpoint: "xs" }}>
				<AppShell.Navbar p='sm'>
					{isAdmin(user?.roles) ? <AdminNavbar /> : <LeftNavbar />}
				</AppShell.Navbar>
				<AppShell.Main>{children}</AppShell.Main>
			</AppShell>
		);
	else return <Navigate to={routes.LOGIN} />;
};

const App: React.FC = () => {
	const { i18n } = useTranslation();
	const dispatch = useAppDispatch();
	const decodedToken: any = token ? jwtDecode(token) : null;
	const fundQueryConfig = getConfig("fund_query");
	const isFundQueryOption1 = +(fundQueryConfig?.DISPLAY_OPTION?.value || 1) === 1;

	useEffect(() => {
		if (token) {
			dispatch(
				setUser({
					username: decodedToken?.preferred_username,
					givenName: decodedToken?.given_name,
					fullName: decodedToken?.name,
					roles: decodedToken?.permissions[0].role,
				}));
			axios.defaults.headers.common.Authorization = `Bearer ${token}`;
		}
		else {
			dispatch(logout())
		}
	}, []);

	return (
		<MantineProvider
			defaultColorScheme='light'
			theme={theme}
		>
			<I18nextProvider i18n={i18n}>
				<Router>
					<Routes>
						<Route path={routes.LOGIN} element={<LoginPage />} />
						<Route
							path={routes.HOME}
							element={
								<AuthLayout>
									<Outlet />
								</AuthLayout>
							}
						>
							<Route index element={
								isAdmin(decodedToken?.permissions[0].role) ?
									<UsersPage /> : <Dashboard />
							} />
							<Route path={routes.USERS} element={<UsersPage />} />
							<Route element={<Dashboard />} />
							<Route path={routes.MARKET_ON_OPEN} element={<HomePage />} />
							<Route path={routes.MARKET} element={<HomePage />} />
							<Route path={routes.MARKET_ON_CLOSE} element={<HomePage />} />
							<Route path={routes.MARKET_FUTURE_DAYS} element={<HomePage />} />
							<Route
								path={`${routes.FUNDSDATA}/:fund_id/:transaction_id/:target_mode/:fund_state/:target_date/:fund_group`}
								element={<FundData />}
							/>
							<Route path={routes.ALLFUNDQUERY} element={isFundQueryOption1 ? <AllFundQuery /> : <AllFundQueryOption2 />} />
							<Route path={`${routes.ORDERCOMPLETION}/:fund_id/:target_date/:target_mode/:fund_state/:transaction_id/`} element={<OrderCompletion />} />
							<Route path={routes.ALLFUNDSTATUS} element={<AllFundStatus />} />
							<Route path={routes.COACMANAGEMENT} element={<CoacManagement />} />
							<Route path={routes.COMING} element={<FundPage />} />
							<Route path={routes.COMINGPREFERENCE} element={<Preferences />} />
							<Route path={routes.COMINGUPDATES} element={<SoftwareUpdate />} />
							<Route path={routes.COMINGSUPPORT} element={<Support />} />
							<Route path={routes.ACCOUNT} element={<AccountPage />} />
						</Route>
						<Route path={routes.DEMOSTARTER} element={<DemoStarter />} />
						<Route path='*' element={<PageNotFound />} />
					</Routes>
				</Router>
			</I18nextProvider>
		</MantineProvider>
	);
};

export default App;
