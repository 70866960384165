import classes from "../../pages/HomePage/HomePage.module.css";
import { CardData, RowInfo } from "../../types";
import { ActionIcon, Box, Collapse, Flex, Text, useMantineTheme } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { AgTableComponent } from '../Agtablehomepage/AgTableComponent';
import { useDisclosure } from '@mantine/hooks';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import SkeletonLoader from "../Common/Skeleton";
import { useAppSelector } from "../../store/hooks";
import { useEffect, useState } from "react";
import ErrorMessage from "../Common/ErrorMessage";
import axios from "axios";
import { services } from "../../services";
import { useInterval } from "../../utils/hooks/useInterval";
import { getCurrentUTCDate } from "../../utils/dateUtils";
import { calculatePercentage, directPercentage } from "../../utils/conversions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { getBadgeStyles } from "../../utils/getStyles";


type Props = {
    category: keyof CardData;
    marketState: string;
    selectedCard: string;
}

const FundsTable = ({ category,
    marketState,
    selectedCard,
}: Props) => {
    const theme = useMantineTheme();
    const { t } = useTranslation();
    const [opened, setOpened] = useState(true);
    const [funds, setFunds] = useState<RowInfo[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const targetDate = useSelector((state: RootState) => state.market.counters.moc_future_days_counters[0].target_date);

    let fundState = {};
    let stateFlags = "";
    const queryParams = {
        market_state: marketState,
        fund_state: `FUND_STATE_${selectedCard.replace(' ', '_').toUpperCase()}`,
        flag: `${selectedCard.replace(' ', '_').toLowerCase()}_${category}`,
        date: marketState === "MARKET_ON_CLOSE_PLUS_DAYS" ? targetDate : getCurrentUTCDate()
    };

    const tableTitle = {
        "total_funds": {
            title1: null,
            class: "",
            bg: "",
            title2: null,
            title3: null
        },
        "in_with_orders": {
            title1: `${funds.length} ${t("FUNDS_WILL_BE")} `,
            class: classes.inPill,
            bg: theme.colors.green[0],
            title2: t("IN"),
            title3: ` ${t("AFTER_PLACING_ORDER")}`
        },
        "out_with_orders": {
            title1: `${funds.length} ${t("FUNDS_WILL_BE")} `,
            class: classes.outPill,
            bg: theme.colors.red[0],
            title2: t("OUT"),
            title3: ` ${t("AFTER_PLACING_ORDER")}`
        },
        "out_with_no_orders": {
            title1: `${funds.length} ${t("FUNDS_REMAIN")} `,
            class: classes.outPill,
            bg: theme.colors.red[0],
            title2: t("OUT"),
            title3: ` ${t("WITH_NO_SUGGESTED_ORDERS")}`
        },
        "start_of_day_recon_not_done": {
            title1: `${funds.length} ${t("FUNDS_RECON_TITLE")} `,
            class: "",
            bg: "",
            title2: null,
            title3: null
        },
        "data_missing": {
            title1: `${funds.length} ${t("FUNDS_ARE_MISSING_DATA")} `,
            class: "",
            bg: "",
            title2: null,
            title3: null
        },
        "benchmark_not_updated": {
            title1: `${funds.length} ${t("FUNDS_BENCHMARK_TITLE")} `,
            class: "",
            bg: "",
            title2: null,
            title3: null
        },
        "in_with_no_order": {
            title1: `${funds.length} ${t("FUNDS_REMAIN")} `,
            class: classes.inPill,
            bg: theme.colors.green[0],
            title2: t("IN"),
            title3: ` ${t("WITH_NO_ACTION")}`
        },
        "in_after_placing_order": {
            title1: `${funds.length} ${t("FUNDS_BECAME")} `,
            class: classes.inPill,
            bg: theme.colors.green[0],
            title2: t("IN"),
            title3: ` ${t("AFTER_PLACING_ORDER")}`
        },
        "out_approved_with_overrides": {
            title1: `${funds.length} ${t("FUNDS_STAYED")} `,
            class: classes.outPill,
            bg: theme.colors.red[0],
            title2: t("OUT"),
            title3: ` ${t("WITH_TOL_OVERRIDE")}`
        }
    }
    const fetchDataForTable = async () => {
        try {
            const res = await axios.get(`${services.FUND_STATE}?${new URLSearchParams(queryParams)}`);
            if (!res.data || !res.data.data || res.data.data.length === 0) {
                setFunds([]);
                setError(t("NO_DATA_FOUND"));
                setLoading(false);
                return;
            }
    
            const fundState = (res.data.data[0] as any)?.fund_state || {};
            const stateFlags = Object.keys(fundState).filter(key => key.endsWith("state_flags"))[0] || "";
    
            const getViolatedTolerance = (violations: any[], type: string) => {
                const target = violations.find(
                    (violation: any) => violation.fund_target.type === type
                );
                return target ? target.violated_tolerance : null;
            };
            
            const getLowerThreshold = (violations: any[], type: string) => {
                const target = violations.find(
                    (violation: any) => violation.fund_target.type === type
                );
                return target ? target.fund_target.lower_threshold : null;
            };
            
            const getUpperThreshold = (violations: any[], type: string) => {
                const target = violations.find(
                    (violation: any) => violation.fund_target.type === type
                );
                return target ? target.fund_target.upper_threshold : null;
            };
            
            // Create a map to store rowID and status ("Me" or "I'm starting")
            const rowIdOrder: { [key: number]: string } = {};
    
            const tableData = res.data.data
                .filter((item: any) => item.fund_state[stateFlags][category])
                .map((item: any, index: number) => {
                    const status = item.is_fund_locked;
                    rowIdOrder[index + 1] = status;
                    console.log("rowIdOrder", item.is_fund_locked)
    
                    return {
                        sr_no: index + 1,
                        fund_id: item.fund_id,
                        fund_summary_type: item.fund_summary_type,
                        fund_name: item.fund_attributes.fund_name,
                        tol_start: item.start_of_the_day_status === "TOLERANCE_OUT" ? t("OUT") : t("IN"),
                        tol_current: (() => {
                            const readyStateFlags =
                                item.fund_state?.fund_ready_state_flags || item.fund_state?.fund_completed_state_flags;
    
                            if (readyStateFlags && Object.values(readyStateFlags).some((flag) => flag)) {
                                const readyState = Object.keys(readyStateFlags).find((key) => readyStateFlags[key]);
    
                                if (readyState) {
                                    return readyState.split("_")[0].toUpperCase();
                                }
                            }
                            return null;
                        })(),
                        order_status: t(item.fund_state?.fund_state),
                        next_step: "Review orders",
                        wip: status,
                        spend_cash_bef: calculatePercentage(item.fund_stats.spendable_cash, item.fund_stats.total_nav),
                        spend_cash_aft: calculatePercentage(item.new_fund_stats.spendable_cash, item.fund_stats.total_nav),
                        unequitized_cash_bef: calculatePercentage(item.fund_stats.unequitized_cash, item.fund_stats.total_nav),
                        unequitized_cash_aft: calculatePercentage(item.new_fund_stats.unequitized_cash, item.fund_stats.total_nav),
                        assets: item.fund_stats.asset_max_active_weight,
                        industry: item.fund_stats.sector_max_active_weight,
                        country: item.fund_stats.country_max_active_weight,
                        currency: item.fund_stats.currency_max_active_weight,
                        size: item.fund_stats.size_max_active_weight,
                        shortpos: item.fund_stats.num_short_positions,
                        nohold: item.fund_stats.num_no_holds,
                        nonbench: item.fund_stats.num_non_benchmark_assets,
                        activerisk: item.fund_stats.active_risk,
                        equityactiverisk: item.fund_stats.active_risk,
                        as_of_date: item.as_of_date,
                        transaction_id: item.transaction_id,
                        target_date_data_date: item.target_date_data_date,
                        is_fund_locked: item.is_fund_locked,
                        fund_group: item.fund_attributes.fund_group,
                        fund_state: item.fund_state.fund_state,
                        total_nav: item.fund_stats.total_nav,
                        spend_cash_bef_violated_tolerances: directPercentage(getViolatedTolerance(
                            item.fund_stats_with_target_violation,
                            "SPENDABLE_CASH"
                        )),
                        spend_cash_bef_violated_tolerances_lower: directPercentage(getLowerThreshold(item.fund_stats_with_target_violation,"SPENDABLE_CASH")),
                        spend_cash_bef_violated_tolerances_upper: directPercentage(getUpperThreshold(item.fund_stats_with_target_violation,"SPENDABLE_CASH")),



                        spend_cash_aft_violated_tolerances: directPercentage(getViolatedTolerance(
                            item.new_fund_stats_with_target_violation,
                            "SPENDABLE_CASH"
                        )),
                        spend_cash_aft_violated_tolerances_lower: directPercentage(getLowerThreshold(item.new_fund_stats_with_target_violation,"SPENDABLE_CASH")),
                        spend_cash_aft_violated_tolerances_upper: directPercentage(getUpperThreshold(item.new_fund_stats_with_target_violation,"SPENDABLE_CASH")),


                        unequitized_cash_bef_violated_tolerances: directPercentage(getViolatedTolerance(
                            item.fund_stats_with_target_violation,
                            "UNEQUITIZED_CASH"
                        )),
                        unequitized_cash_bef_violated_tolerances_lower: directPercentage(getLowerThreshold(item.fund_stats_with_target_violation,"UNEQUITIZED_CASH")),
                        unequitized_cash_bef_violated_tolerances_upper: directPercentage(getUpperThreshold(item.fund_stats_with_target_violation,"UNEQUITIZED_CASH")),



                        unequitized_cash_aft_violated_tolerances: directPercentage(getViolatedTolerance(
                            item.new_fund_stats_with_target_violation,
                            "UNEQUITIZED_CASH"
                        )),
                        unequitized_cash_aft_violated_tolerances_lower: directPercentage(getLowerThreshold(item.new_fund_stats_with_target_violation,"UNEQUITIZED_CASH")),
                        unequitized_cash_aft_violated_tolerances_upper: directPercentage(getUpperThreshold(item.new_fund_stats_with_target_violation,"UNEQUITIZED_CASH")),
                        
                    };
                });
    
            // Store rowIdOrder in localStorage as a JSON string
            localStorage.setItem('FundLocked', JSON.stringify(rowIdOrder));
    
            if (tableData.length > 0) {
                localStorage.setItem('FundLocked', JSON.stringify(rowIdOrder));
                setFunds(tableData);
                setError(null);
            } else {
                setFunds([]);
                setError(t("NO_DATA_FOUND"));
            }
    
        } catch (error: any) {
            console.error("Error making API request:", error);
            setError(error.response.status);
        } finally {
            setLoading(false);
        }
    };
    
    
    useEffect(() => {
        setError(null);
        setLoading(true);
        fetchDataForTable()
    }, [marketState]);

    useInterval({
        callback: fetchDataForTable,
        type: "func"
    });

    useEffect(() => {
        if (funds.length === 0) {
            setOpened(false);
        } else {
            setOpened(true);
        }
    }, [funds.length]);

    const toggle = () => {
        setOpened(prev => !prev);
    };
    const { inBadge, outBadge } = getBadgeStyles(theme);

    if (loading) return <SkeletonLoader />
    // if (error) return <ErrorMessage errorMessage={error}/>

    return (
        <Box mb="xl" key={category}>
            <Flex gap="xs"
                justify="flex-start"
                align="center"
                direction="row"
                wrap="wrap"
                style={{ paddingLeft: "0.1rem" }}
                mb={"xxs"}>
                <Text fw="600" size="1rem">
                    {tableTitle[category] &&
                        <>
                            {tableTitle[category].title1}
                            {tableTitle[category].title2 &&
                                <>
                                    <span>
                                        {tableTitle[category].title2 === 'IN' ? inBadge : outBadge}
                                    </span>
                                    {tableTitle[category].title3}
                                </>
                            }
                        </>
                    }
                </Text>
                <ActionIcon variant="transparent"
                    color={theme.colors.dark[9]}
                    aria-label="Settings"
                    onClick={toggle}>
                    {opened ? <IconChevronUp /> : <IconChevronDown />}
                </ActionIcon>
            </Flex>
            <Collapse in={opened}>
                <AgTableComponent rowData={funds} />
            </Collapse>
        </Box>
    )
}

export default FundsTable;