import React from 'react';
import { Box } from '@mantine/core'; // Adjust import if using a different library
import CollapsibleSection from './CollapsibleSection'; // Adjust import as needed
import ActionItemsTable from './ActionItemsTable'; // Adjust import as needed

interface CollapsibleSectionGroupProps {
  sections: {
    title: string;
    count: number;
    rowData: any[];
    updateSelectedRowCount: (count: number) => void;
  }[];
  handleRowSelection: (rows: any[]) => void;
  handleSelectionChange: (rows: any[]) => void;
  handleCommentsChange: (comments: any[]) => void;
}

const CollapsibleSectionGroup: React.FC<CollapsibleSectionGroupProps> = ({
  sections,
  handleRowSelection,
  handleSelectionChange,
  handleCommentsChange
}) => {
  return (
    <Box mt={"-1.1rem"}>
      {sections.map((section, index) => (
        <CollapsibleSection key={index} title={section.title} count={section.count}>
          <ActionItemsTable
            setSelectedRows={handleRowSelection}
            rowData={section.rowData}
            updateSelectedRowCount={section.updateSelectedRowCount}
            onSelectionChange={handleSelectionChange}
            onCommentsChange={handleCommentsChange}
          />
        </CollapsibleSection>
      ))}
    </Box>
  );
};

export default CollapsibleSectionGroup;
